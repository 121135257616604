<template>
  <div>
    <div class="holder">
      <div class="holder-child">
        <div class="nav-tab">
          <ul class="nav nav-tabs tabs-nav" id="myTab" role="tablist">
            <li class="nav-item" v-if="who_you === 'Tenant' || 'Landlord'">
              <a
                v-if="userAccount === 'individual'"
                class="nav-link active"
                id="biodata-tab"
                data-toggle="tab"
                href="#biodata"
                role="tab"
                aria-controls="biodata"
                aria-selected="true"
                >Biodata</a
              >
              <a
                v-else
                class="nav-link active"
                id="biodata-tab"
                data-toggle="tab"
                href="#biodata"
                role="tab"
                aria-controls="biodata"
                aria-selected="true"
                >Profile</a
              >
            </li>
            <li
              class="nav-item"
              v-if="
                (who_you === 'Tenant' || 'Landlord') &&
                  userAccount === 'individual'
              "
            >
              <a
                class="nav-link"
                id="emergency-tab"
                data-toggle="tab"
                href="#emergency"
                role="tab"
                aria-controls="emergency"
                aria-selected="false"
                >In Case of Emergency</a
              >
            </li>
            <li class="nav-item" v-if="who_you === 'Tenant' || 'Landlord'">
              <a
                class="nav-link"
                id="identification-tab"
                data-toggle="tab"
                href="#identification"
                role="tab"
                aria-controls="identification"
                aria-selected="false"
                >Means of Identification</a
              >
            </li>
            <li
              class="nav-item"
              v-if="who_you === 'Tenant' && userAccount === 'individual'"
            >
              <a
                class="nav-link"
                id="guarantor-tab"
                data-toggle="tab"
                href="#guarantor"
                role="tab"
                aria-controls="guarantor"
                aria-selected="false"
                >Guarantor/Indemnitor</a
              >
            </li>
            <li class="nav-item" v-if="userAccount === 'individual'">
              <a
                class="nav-link"
                id="nextofkin-tab"
                data-toggle="tab"
                href="#nextofkin"
                role="tab"
                aria-controls="nextofkin"
                aria-selected="false"
                >Next of KIN</a
              >
            </li>
            <li
              class="nav-item"
              v-if="
                (who_you === 'Tenant' || 'Landlord') &&
                  userAccount === 'individual'
              "
            >
              <a
                class="nav-link"
                id="occupation-tab"
                data-toggle="tab"
                href="#occupation"
                role="tab"
                aria-controls="occupation"
                aria-selected="false"
                >Occupation</a
              >
            </li>
            <li
              class="nav-item"
              v-if="who_you === 'Tenant' && userAccount === 'individual'"
            >
              <a
                class="nav-link"
                id="financial-tab"
                data-toggle="tab"
                href="#financial"
                role="tab"
                aria-controls="financial"
                aria-selected="false"
                >Financial Status</a
              >
            </li>
            <li
              class="nav-item"
              v-if="who_you === 'Landlord' || who_you === 'Tenant'"
            >
              <a
                class="nav-link"
                id="referrer-tab"
                data-toggle="tab"
                href="#referrer"
                role="tab"
                aria-controls="referrer"
                aria-selected="false"
                >Referer</a
              >
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <!-- Bio data begins -->
            <div
              class="tab-pane fade show active"
              id="biodata"
              role="tabpanel"
              aria-labelledby="biodata-tab"
            >
              <div class="bio-holder">
                <div class="body-holder">
                  <div class="body-wrap">
                    <div class="container body-child">
                      <div class="card">
                        <div class=" card-body my-card-body  container-fluid">
                          <div class="text-header2">
                            <router-link to="viewbio">
                              <button
                                class="btn"
                                v-if="userAccount === 'individual'"
                              >
                                View Biodata
                              </button>
                              <button class="btn" v-else>
                                View Profile
                              </button>
                            </router-link>
                          </div>
                          <div class="row">
                            <div class="col-md-6 liks_row">
                              <div class="profile_pix_section">
                                <h6>Profile Photo</h6>
                                <div>
                                  <div class="profile_img_circle">
                                    <!-- <img
                                    @click="$refs.file9.click()"
                                      v-if="user.passportfile == '' ||
                                          user.passportfile == null && filePath"
                                      class="profile_img rounded-circle"
                                      :src="filePath"
                                      alt="profile"
                                    /> -->
                                    <!-- profile_img -->
                                    <img
                                      @click="$refs.file9.click()"
                                      v-if="
                                        user.passportfile == '' ||
                                          (user.passportfile == null &&
                                            !filePath)
                                      "
                                      class="blue_avatar"
                                      src="../assets/images/user-blue-bg.svg"
                                      alt="profile"
                                    />
                                    <img
                                      @click="$refs.file9.click()"
                                      v-else
                                      class="profile_img rounded-circle"
                                      v-bind:src="user.passportfile || filePath"
                                      alt=""
                                    />
                                  </div>
                                  <p class="file_name" v-if="fileName">
                                    {{ fileName }}
                                  </p>
                                  <div class="upload_profile">
                                    <button
                                      class="btn"
                                      @click="changeProfileImage()"
                                    >
                                      Upload Photo
                                      <i
                                        class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                                        v-if="loader"
                                      ></i>
                                    </button>
                                    <!-- <button
                                      class="btn"
                                      @click="$refs.file9.click()"
                                    >
                                      Change Photo
                                    </button> -->
                                    <input
                                      type="file"
                                      class="d-none"
                                      ref="file9"
                                      @change="getFileName2($event)"
                                    />
                                  </div>

                                  <hr class="profile_divider d-none" />
                                </div>
                                <h6 class="d-none">Social links</h6>
                                <form class="d-none">
                                  <div class="form-group row">
                                    <div
                                      class="input-group col-md-10 col-sm-12 lrpadding"
                                    >
                                      <div class="input-group-prepend">
                                        <div
                                          class="input-group-text side-group"
                                        >
                                          <img
                                            src="../assets/images/whatsapp-icon.svg"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <input
                                        type="text"
                                        class="form-control input-bar"
                                        id="whatsapp"
                                        name="whatsapp"
                                        placeholder="WhatsApp number"
                                        v-model="user.whatsapp"
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <div
                                      class="input-group col-md-10 col-sm-12 lrpadding"
                                    >
                                      <div class="input-group-prepend">
                                        <div
                                          class="input-group-text side-group"
                                        >
                                          <img
                                            src="../assets/images/website-icon.svg"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <input
                                        type="text"
                                        class="form-control input-bar"
                                        id="website"
                                        name="website"
                                        placeholder="Website"
                                        v-model="user.website"
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <div
                                      class="input-group col-md-10 col-sm-12 lrpadding"
                                    >
                                      <div class="input-group-prepend">
                                        <div
                                          class="input-group-text side-group"
                                        >
                                          <img
                                            src="../assets/images/facebook-icon.svg"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <input
                                        type="text"
                                        class="form-control input-bar"
                                        id="facebook"
                                        name="facebook"
                                        placeholder="Facebook username"
                                        v-model="user.facebook"
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <div
                                      class="input-group col-md-10 col-sm-12 lrpadding"
                                    >
                                      <div class="input-group-prepend">
                                        <div
                                          class="input-group-text side-group"
                                        >
                                          <img
                                            src="../assets/images/insta-icon.svg"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <input
                                        type="text"
                                        class="form-control input-bar"
                                        id="instagram"
                                        name="instagram"
                                        placeholder="Instagram handle"
                                        v-model="user.instagram"
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <div
                                      class="input-group col-md-10 col-sm-12 lrpadding"
                                    >
                                      <div class="input-group-prepend">
                                        <div
                                          class="input-group-text side-group"
                                        >
                                          <img
                                            src="../assets/images/twitter-icon.svg"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <input
                                        type="text"
                                        class="form-control input-bar"
                                        id="twitter"
                                        name="twitter"
                                        placeholder="Twitter handle"
                                        v-model="user.twitter"
                                      />
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div class="col-md-6 user_data">
                              <form class="bio_form">
                                <div class="text-header3">
                                  <h1>We want to know you BETTER</h1>
                                </div>
                                <div class="form-group row">
                                  <!-- <label class="select-label" for="firstName">First name</label> -->
                                  <input
                                    v-if="userAccount === 'individual'"
                                    type="text"
                                    name="firstname"
                                    class="form-control input-bar col-md-10 col-sm-12"
                                    id="firstName"
                                    placeholder="| Your First Name"
                                    v-model="user.firstname"
                                  />
                                  <input
                                    v-else
                                    type="text"
                                    name="firstname"
                                    class="form-control input-bar col-md-10 col-sm-12"
                                    id="firstName"
                                    placeholder="| Organisation Name"
                                    v-model="user.firstname"
                                  />
                                  <!-- v-model="firstName" -->
                                </div>
                                <div
                                  class="form-group row"
                                  v-if="userAccount === 'individual'"
                                >
                                  <input
                                    type="text"
                                    name="lastname"
                                    class="form-control col-md-10 col-sm-12 input-bar"
                                    id="lastName"
                                    placeholder="| Your Last Name "
                                    v-model="user.lastname"
                                  />
                                </div>
                                <div
                                  class="form-group row"
                                  v-if="userAccount === 'individual'"
                                >
                                  <input
                                    type="text"
                                    class="form-control col-md-10 col-sm-12 input-bar"
                                    id="otherName"
                                    placeholder="| Any other name?"
                                    v-model="user.othernames"
                                  />
                                </div>
                                <!-- <div class="form-group row">
                                  <input
                                    type="email"
                                    name="email"
                                    class="form-control col-md-10 col-sm-12 input-bar"
                                    id="email"
                                    placeholder="| Email"
                                    v-model="user.email"
                                  />
                                </div> -->
                                <div class="form-group row">
                                  <input
                                    type="text"
                                    name="phone"
                                    class="form-control col-md-10 col-sm-12 input-bar"
                                    id="phoneName"
                                    placeholder="| Phone Number"
                                    v-model="user.phone"
                                  />
                                </div>

                                <div class="form-group row">
                                  <input
                                    type="text"
                                    name="address"
                                    class="form-control col-md-10 col-sm-12 input-bar"
                                    id="address"
                                    placeholder="| Address"
                                    v-model="user.address"
                                  />
                                </div>
                                <!-- <div class="form-group row">
                                  <input
                                    type="text"
                                    class="form-control col-md-10 col-sm-12 input-bar"
                                    id="state"
                                    placeholder="| State"
                                  />
                                  
                                </div> -->
                                <!-- <div class="form-group row">
                                  <input
                                    type="text"
                                    class="form-control col-md-10 col-sm-12 input-bar"
                                    id="city"
                                    placeholder="| City"
                                  />
                                </div> -->
                                <label class="select-label" for="country"
                                  >Country</label
                                >
                                <div class="form-group row">
                                  <!-- v:bind:selected="country.id===user.countryid" -->

                                  <select
                                    @change="getStatesFromCountry($event)"
                                    name="country"
                                    class="form-control col-md-10 col-sm-12 input-bar"
                                    id="country"
                                    v-model="user.countryid"
                                  >
                                    <option value="" selected disabled
                                      >--Select country--</option
                                    >
                                    <option
                                      v-for="(country, index) in getCountries"
                                      :key="index"
                                      v-bind:value="country.id"
                                      >{{ country.name }}</option
                                    >
                                  </select>
                                </div>
                                <!-- <div class="form-group row">
                                  <select
                                    name="state"
                                    v-model="user.stateid"
                                    class="form-control col-md-10 col-sm-12 input-bar"
                                    id="exampleFormControlSelect2"
                                  >
                                    <option value="" disabled
                                      >Select state</option
                                    >
                                    <option
                                      v-for="(city, index) in getcities"
                                      :key="index"
                                      v-bind:value="state.id"
                                      >{{ state.name }}</option
                                    >
                                  </select>
                                </div> -->
                                <label class="select-label" for="state"
                                  >State</label
                                >
                                <div class="form-group row">
                                  <select
                                    @change="getStateId($event)"
                                    name="state"
                                    v-model="user.stateid"
                                    class="form-control col-md-10 col-sm-12 input-bar"
                                    id="state"
                                  >
                                    <option selected value="" disabled
                                      >--Select state--</option
                                    >
                                    <option
                                      v-for="(state, index) in getstates"
                                      :key="index"
                                      v-bind:value="state.id"
                                      >{{ state.name }}</option
                                    >
                                    <!-- :selected="user.stateid == state.id" -->
                                  </select>
                                </div>
                                <label class="select-label" for="city"
                                  >City</label
                                >
                                <div class="form-group row">
                                  <select
                                    name="city"
                                    v-model="user.cityid"
                                    class="form-control col-md-10 col-sm-12 input-bar"
                                    id="city"
                                  >
                                    <option value="" selected disabled
                                      >--Select city--</option
                                    >
                                    <option
                                      v-for="(city, index) in getcities"
                                      :key="index"
                                      v-bind:value="city.id"
                                      >{{ city.name }}</option
                                    >
                                  </select>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div class="update_bio">
                          <button @click="profileSetup()" class="btn btn-block">
                            SAVE
                            <!-- <img class="d-none" src="../assets/images/white-save.svg" alt=""> -->
                            <i
                              class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                              v-if="loaderProfile"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Bio data ends -->

            <!-- in case of emangecy contact -->
            <div
              class="tab-pane fade"
              id="emergency"
              role="tabpanel"
              aria-labelledby="emergency-tab"
            >
              <div class="bio-holder">
                <div class="body-holder">
                  <div class="body-wrap">
                    <div class="container body-child">
                      <div class="card">
                        <div class="card-body my-card-body">
                          <div class="text-header">
                            <h1>IN CASE OF EMERGENCY</h1>
                          </div>
                          <form>
                            <div
                              class="formField"
                              v-for="(emergency, index) in emergencyInfo"
                              :key="index"
                            >
                              <div class="row">
                                <div
                                  class="col-md-6 col-sm-12 emergency-top-header"
                                >
                                  <div>
                                    <h6 class="formHeader">
                                      Emergency contact form
                                    </h6>
                                    <span
                                      v-if="emergencyInfo.length != 1"
                                      @click.prevent="
                                        removeMoreEmergencyContact(index)
                                      "
                                      >X</span
                                    >
                                  </div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control input-bar col-md-6 col-sm-12"
                                  id="fullNameEmer"
                                  name="fullnameEmer"
                                  placeholder="| Full Name"
                                  v-model="fullnameem"
                                />
                                <!-- v-model="emergency.fullname" -->
                              </div>
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control col-md-6 col-sm-12 input-bar"
                                  id="phoneNumberEmer"
                                  name="phoneEmer"
                                  placeholder="| Phone Number"
                                  v-model="phoneem"
                                />
                                <!-- v-model="emergency.phone" -->
                              </div>
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control col-md-6 col-sm-12 input-bar"
                                  id="addressEmer"
                                  name="addressEmer"
                                  placeholder="| Address"
                                  v-model="addressem"
                                />
                                <!-- v-model="emergency.address" -->
                              </div>
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control col-md-6 col-sm-12 input-bar"
                                  id="relationshipEmer"
                                  name="relationshipEmer"
                                  placeholder="| Relationship"
                                  v-model="relationshipem"
                                />
                                <!-- v-model="emergency.relationship" -->
                              </div>
                            </div>
                            <!-- <div class="addMoreForm-holder">
                              <p @click="addMoreEmergencyContact()">
                                Add More +
                              </p>
                            </div> -->

                            <div v-if="errors1.length">
                              <ul class="error-ul">
                                <li
                                  v-for="error in errors1"
                                  :key="error.error"
                                  class="errorMessage"
                                >
                                  {{ error }}
                                </li>
                              </ul>
                            </div>
                            <div class="form-group row">
                              <!--  @click.prevent="addEmergencyInfo()" -->
                              <button
                                v-if="fulldata.emergency_details == null"
                                @click.prevent="createEmergency()"
                                class="btn setup-btn btn-block col-md-6 col-sm-12"
                              >
                                SAVE
                                <i
                                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                                  v-if="loader1"
                                ></i>
                              </button>
                              <button
                                v-if="fulldata.emergency_details != null"
                                @click.prevent="editEmergency()"
                                class="btn setup-btn btn-block col-md-6 col-sm-12"
                              >
                                UPDATE
                                <i
                                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                                  v-if="loader1"
                                ></i>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Incase of emangency ends -->

            <!-- Means of Identification -->
            <div
              class="tab-pane fade"
              id="identification"
              role="tabpanel"
              aria-labelledby="identification-tab"
            >
              <div class="bio-holder">
                <div class="body-holder">
                  <div class="body-wrap">
                    <div class="container body-child">
                      <div class="card">
                        <div class="card-body my-card-body">
                          <div class="text-header">
                            <h1>MEANS OF IDENTIFICATION</h1>
                          </div>
                          <form>
                            <div class="form-group row">
                              <select
                                v-model="modeOfIdSelected"
                                class="form-control col-md-6 col-sm-12 input-bar"
                                id="exampleFormControlSelect3"
                                @change="getValue($event)"
                              >
                                <option value="" disabled
                                  >| Select Document Type</option
                                >
                                <option
                                  v-for="(identify, index) in identifcationType"
                                  :key="index"
                                  v-bind:value="identify.name"
                                  >{{ identify.name }}</option
                                >
                                <!-- <option value="Voters Card">Voters Card</option>
                                <option class="International Passport"
                                  >International Passport</option -->
                                >
                              </select>
                            </div>
                            <small>{{ fileName }}</small>
                            <div class="form-group row">
                              <div class="uploadFile-sizeHolder">
                                <p>
                                  <span
                                    id="meansID"
                                    @click="$refs.file.click()"
                                    style="cursor:pointer;"
                                    class=""
                                    >Upload Document
                                  </span>
                                  <input
                                    @change="getFileName($event)"
                                    class="d-none"
                                    ref="file"
                                    type="file"
                                  />
                                </p>

                                <!-- <span class="fileSize-holder">
                                  <img
                                    src="../assets/images/blue-good.svg"
                                    alt=""
                                  />
                                  <img
                                  src="../assets/images/red-wrong.svg"
                                  alt=""
                                />
                                  <p>0.9KB</p>
                                </span> -->
                              </div>
                            </div>
                            <div class="file-check-holder">
                              <span v-if="fileSizeType && fileSizeInMb <= 1">
                                <img
                                  src="../assets/images/blue-good.svg"
                                  alt=""
                                />
                                <p>{{ fileSizeInMb }}MB</p>
                              </span>
                              <span v-if="fileSizeType && fileSizeInMb > 5">
                                <img
                                  src="../assets/images/red-wrong.svg"
                                  alt=""
                                />
                                <p>{{ fileSizeInMb }}MB</p>
                                <p>Maximun size 5MB</p>
                              </span>
                            </div>
                            <div class="form-group row">
                              <button
                                @click.prevent="addMeansofIdentification()"
                                class="btn setup-btn btn-block col-md-6 col-sm-12"
                              >
                                SAVE
                                <i
                                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                                  v-if="loaderM"
                                ></i>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Means of Identification ends -->

            <!-- Guarantor form begins -->
            <div
              class="tab-pane fade"
              id="guarantor"
              role="tabpanel"
              aria-labelledby="guarantor-tab"
            >
              <div class="bio-holder">
                <div class="body-holder">
                  <div class="body-wrap">
                    <div class="container body-child">
                      <div class="card">
                        <div class="card-body my-card-body">
                          <div class="text-header">
                            <h1>GUARANTOR/INDEMNITOR FORM</h1>
                          </div>
                          <form>
                            <div class="formField">
                              <!-- <div class="row">
                                <div
                                  class="col-md-6 col-sm-12 emergency-top-header"
                                >
                                  <div>
                                    <h6 class="formHeader">
                                      Guarantor contact form
                                    </h6>
                                    <span
                                      v-if="guarantorInfo.length != 1"
                                      @click.prevent="
                                        removeMoreGuarantorContact(index)
                                      "
                                      >X</span
                                    >
                                  </div>
                                </div>
                            </div> -->
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control input-bar col-md-6 col-sm-12"
                                  id="guarantorFullname"
                                  name="gfullname"
                                  placeholder="| Guarantors FullName"
                                  v-model="fullnameg"
                                />
                                <!-- v-model="guarantorObj.fullname" -->
                              </div>
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control col-md-6 col-sm-12 input-bar"
                                  id="guarantorPhone"
                                  name="gphone"
                                  placeholder="| Phone Number"
                                  v-model="phoneg"
                                />
                                <!-- v-model="guarantorObj.phone" -->
                              </div>
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control col-md-6 col-sm-12 input-bar"
                                  id="guarantorEmail"
                                  name="gemail"
                                  placeholder="| Email"
                                  v-model="emailg"
                                />
                                <!-- v-model="guarantorObj.email" -->
                              </div>

                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control col-md-6 col-sm-12 input-bar"
                                  id="guarantorAddress"
                                  name="gaddress"
                                  placeholder="| Address"
                                  v-model="addressg"
                                />
                                <!-- v-model="guarantorObj.address" -->
                              </div>
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control col-md-6 col-sm-12 input-bar"
                                  id="guarantorRelationship"
                                  name="grelationship"
                                  placeholder="| Relationship"
                                  v-model="relationshipg"
                                />
                                <!-- v-model="guarantorObj.relationship -->
                              </div>
                              <div class="uploadDocument-holder">
                                <span
                                  ><img
                                    src="../assets/images/user-blue-bg.svg"
                                    alt=""
                                    srcset=""
                                /></span>
                                <span @click="$refs.file2.click()"
                                  >Upload ID/Passport
                                  <i
                                    class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                                    v-if="loader2"
                                  ></i
                                ></span>
                                <input
                                  @change="addGuarantorPassport($event)"
                                  class="d-none"
                                  ref="file2"
                                  type="file"
                                />
                                <span>JPEG, PNG</span>
                                <span>Max size 1MB</span>
                              </div>
                              <!-- <div class="guarantorDocument-holder">
                                <span
                                  ><img
                                    src="../assets/images/guarantor-form.svg"
                                    alt=""
                                    srcset=""
                                /></span>
                                <span @click="$refs.file5.click()"
                                  >Upload Guarantors form
                                  <i
                                    class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                                    v-if="loader3"
                                  ></i
                                ></span>
                                <input
                                  @change="addGurantorFormDoc($event)"
                                  class="d-none"
                                  ref="file5"
                                  type="file"
                                />
                                <span>JPEG, JPG, PNG</span>
                                <span>Max size 2MB</span>
                              </div> -->
                            </div>
                            <!-- <div class="guarantor-addMore-form">
                              <p @click.prevent="addMoreGuarantorContact()">
                                Add More Guarators +
                              </p>
                            </div> -->
                            <div class="form-group row">
                              <button
                                v-if="fulldata.gurantor == null"
                                @click.prevent="createGuarantor()"
                                class="btn setup-btn btn-block col-md-6 col-sm-12"
                              >
                                SAVE
                                <i
                                  class="fa fa-spinner fa-spin btn-loader"
                                  v-if="loader"
                                ></i>
                              </button>
                              <button
                                v-if="fulldata.gurantor != null"
                                @click.prevent="editGuarantor()"
                                class="btn setup-btn btn-block col-md-6 col-sm-12"
                              >
                                UPDATE
                                <i
                                  class="fa fa-spinner fa-spin btn-loader"
                                  v-if="loader"
                                ></i>
                              </button>
                            </div>
                            <div v-if="errors2.length">
                              <ul class="error-ul">
                                <li
                                  v-for="error in errors2"
                                  :key="error.error"
                                  class="errorMessage"
                                >
                                  {{ error }}
                                </li>
                              </ul>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Garantor form ends -->

            <!-- Next of kin begins -->
            <div
              class="tab-pane fade"
              id="nextofkin"
              role="tabpanel"
              aria-labelledby="nextofkin-tab"
            >
              <div class="bio-holder">
                <div class="body-holder">
                  <div class="body-wrap">
                    <div class="container body-child">
                      <div class="card">
                        <div class="card-body my-card-body">
                          <div class="text-header">
                            <h1>NEXT OF KIN</h1>
                          </div>
                          <form>
                            <div class="form-group row">
                              <input
                                type="text"
                                class="form-control input-bar col-md-6 col-sm-12"
                                id="nokFullName"
                                name="nokfullname"
                                placeholder="| Next of Kin's Full Name"
                                v-model="fullnamenok"
                              />
                            </div>
                            <div class="form-group row">
                              <input
                                type="text"
                                class="form-control col-md-6 col-sm-12 input-bar"
                                id="nokPhone"
                                name="nokphone"
                                placeholder="| Next of Kin's Phone Number"
                                v-model="phonenok"
                              />
                            </div>
                            <div class="form-group row">
                              <input
                                type="text"
                                class="form-control col-md-6 col-sm-12 input-bar"
                                id="nokemail"
                                name="nokemail"
                                placeholder="| Email of Next of Kin"
                                v-model="emailnok"
                              />
                            </div>

                            <div class="form-group row">
                              <input
                                type="text"
                                class="form-control col-md-6 col-sm-12 input-bar"
                                id="nokaddress"
                                name="nokaddress"
                                placeholder="| Address"
                                v-model="addressnok"
                              />
                            </div>
                            <div class="form-group row">
                              <input
                                type="text"
                                class="form-control col-md-6 col-sm-12 input-bar"
                                id="nokRelationship"
                                name="nokrelationship"
                                placeholder="| Relationship"
                                v-model="relationshipnok"
                              />
                            </div>
                            <div v-if="errorsN.length">
                              <ul class="error-ul">
                                <li
                                  v-for="error in errorsN"
                                  :key="error.error"
                                  class="errorMessage"
                                >
                                  {{ error }}
                                </li>
                              </ul>
                            </div>
                            <div class="form-group row">
                              <button
                                @click.prevent="addNextofKin()"
                                class="btn setup-btn btn-block col-md-6 col-sm-12"
                              >
                                SAVE
                                <i
                                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                                  v-if="loaderN"
                                ></i>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Next of kin ends -->

            <!-- Occupation begins -->
            <div
              class="tab-pane fade"
              id="occupation"
              role="tabpanel"
              aria-labelledby="oocupation-tab"
            >
              <div class="bio-holder">
                <div class="body-holder">
                  <div class="body-wrap">
                    <div class="container body-child">
                      <div class="card">
                        <div class="card-body my-card-body">
                          <div class="text-header">
                            <h1>OCCUPATION</h1>
                          </div>
                          <form>
                            <div class="form-group row occupation-holder">
                              <!-- @change="employmentStatus()" -->
                              <select
                                @change="employmentStatusFunc(emplymentStatus)"
                                v-model="emplymentStatus"
                                class="form-control col-md-6 col-sm-12 input-bar"
                                id="exampleFormControlSelect4"
                              >
                                <option selected value="" disabled
                                  >| Employment Status</option
                                >
                                <option
                                  v-for="(occupation, index) in occupationtypes"
                                  :key="index"
                                  v-bind:value="occupation.occupation"
                                  >{{ occupation.occupation }}</option
                                >
                              </select>
                            </div>

                            <div id="employed">
                              <div class="col-md-6 col-sm-12 pl-0 pr-0 m-auto">
                                <div class="form-group panel-input" id="org" ref="orgg">
                                  <input
                                    @focus="runOption($event)"
                                    @keyup="filterOrganization(nameofOrganization)"
                                    ref="openPanel"
                                    type="text"
                                    class="form-control input-bar"
                                    id="panel"
                                    name="title"
                                    v-model="nameofOrganization"
                                    placeholder="Select or enter organization name"
                                  />
                                  <span
                                    @click="openOption = false"
                                    v-if="openOption"
                                  >
                                    <i class="fa fa-chevron-up"></i>
                                  </span>
                                  <span
                                    @click="openOption = true"
                                    v-if="!openOption"
                                  >
                                    <i class="fa fa-chevron-down"></i>
                                  </span>
                                  <div v-if="openOption && organizations.length > 0" class="option-list">
                                    <div
                                      class="handler-list"
                                      v-for="(item, index) in organizations"
                                      :key="index"
                                    >
                                      <li
                                        :class="[
                                          item.firstname === nameofOrganization
                                            ? 'activeHandler'
                                            : ''
                                        ]"
                                        @click="
                                          selectPanel(item.firstname, item.id, item)
                                        "
                                      >
                                        {{ item.firstname }}
                                      </li>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control input-bar col-md-6 col-sm-12"
                                  id="nameofOrganization"
                                  name="nameofOrganization"
                                  placeholder="|Name of Organization"
                                  v-model="nameofOrganization"
                                />
                              </div> -->
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control input-bar col-md-6 col-sm-12"
                                  id="addressofOrganization"
                                  name="employAddress"
                                  placeholder="|Address of Organization"
                                  v-model="employAddress"
                                  :disabled="disableInput"
                                />
                              </div>
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control input-bar col-md-6 col-sm-12"
                                  id="jobTitle"
                                  name="jobTitle"
                                  placeholder="| Job Tittle"
                                  v-model="jobTitle"
                                />
                              </div>
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control input-bar col-md-6 col-sm-12"
                                  id="dateOfEmployment"
                                  placeholder="| Date of employment"
                                  name="doeEmployed"
                                  onfocus="(this.type='date')"
                                  v-model="dateofEmployment"
                                />
                              </div>
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control input-bar col-md-6 col-sm-12"
                                  id="contactPhoneNumber"
                                  name="employedPhone"
                                  placeholder="| Contact Phone Number"
                                  v-model="employPhone"
                                  :disabled="disableInput"
                                />
                              </div>
                            </div>
                            <div id="unemployed">
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control input-bar col-md-6 col-sm-12"
                                  id="lastPlaceofEmployment"
                                  name="lastWorkPlace"
                                  placeholder="| Last place of employment"
                                  v-model="lastWorkPlace"
                                />
                              </div>
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control input-bar col-md-6 col-sm-12"
                                  id="DateOfDisengagement"
                                  placeholder="| Date of disengagement"
                                  onfocus="(this.type='date')"
                                  name="dateofdisengagement"
                                  v-model="dateOfDisengagement"
                                />
                              </div>
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control input-bar col-md-6 col-sm-12"
                                  id="lastEmploymentAddress"
                                  name="unemployedAddress"
                                  placeholder="| Address"
                                  v-model="unemployedAddress"
                                />
                              </div>
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control input-bar col-md-6 col-sm-12"
                                  id="lastEmployerPhone"
                                  name="unemployedPhone"
                                  placeholder="| Contact Phone Number"
                                  v-model="unemployedPhone"
                                />
                              </div>
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control input-bar col-md-6 col-sm-12"
                                  id="currentSouceOfIncome"
                                  name="currentSourceOfIncome"
                                  placeholder="| Current source of Income"
                                  v-model="currentSourceOfIncome"
                                />
                              </div>
                            </div>
                            <div id="enterpreneur">
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control input-bar col-md-6 col-sm-12"
                                  id="businessName"
                                  name="businessName"
                                  placeholder="| Name of Business"
                                  v-model="nameOfBusiness"
                                />
                              </div>
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control input-bar col-md-6 col-sm-12"
                                  id="businessType"
                                  name="typeOfBusiness"
                                  placeholder="| Type of Business"
                                  v-model="typeOfBusiness"
                                />
                              </div>
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control input-bar col-md-6 col-sm-12"
                                  id="businessSector"
                                  name="businessSector"
                                  placeholder="| Business Sector"
                                  v-model="businessSector"
                                />
                              </div>
                              <div class="form-group row">
                                <input
                                  type="text"
                                  class="form-control input-bar col-md-6 col-sm-12"
                                  id="businessLocation"
                                  name="businessAddress"
                                  placeholder="| Business Location/Address"
                                  v-model="businessAddress"
                                />
                              </div>
                              <div class="form-group row">
                                <select
                                  @change="registeredBusiness($event)"
                                  v-model="isRegistered"
                                  class="form-control col-md-6 col-sm-12 input-bar"
                                  id="exampleFormControlSelect5"
                                >
                                  <option value="" selected disabled
                                    >Is you business Registered?</option
                                  >
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </select>
                              </div>

                              <div id="registeredBusiness" class="">
                                <div class="text-header">
                                  <h1>BUSINESS INFORMATION</h1>

                                  <div class="form-group row">
                                    <select
                                      @change="bizType($event)"
                                      v-model="businessRegType"
                                      class="form-control col-md-6 col-sm-12 input-bar"
                                      id="exampleFormControlSelect6"
                                    >
                                      <option value="" selected disabled
                                        >Type of Registration</option
                                      >
                                      <option value="Business Name"
                                        >Business Name</option
                                      >
                                      <option value="Limited Liability Company"
                                        >Limited Liability Company</option
                                      >
                                      <option value="Incorpurated Trustee"
                                        >Incorpurated Trustee</option
                                      >
                                    </select>
                                  </div>
                                  <div v-if="checkBizType">
                                    <div class="form-group row">
                                      <input
                                        type="text"
                                        class="form-control input-bar col-md-6 col-sm-12"
                                        id="registrationNumber"
                                        name="regNumnber"
                                        placeholder="| Registration Number"
                                        v-model="registrationNumber"
                                      />
                                    </div>

                                    <div
                                      class="uplaodCertificate-holder d-none"
                                    >
                                      <div class="img-doc-holder">
                                        <img
                                          src="../assets/images/document-upload.svg"
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <span
                                          @click="$refs.file3.click()"
                                          class="docUpload"
                                          >Upload File (doc.)
                                          <i
                                            class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                                            v-if="loader4"
                                          ></i>
                                        </span>
                                        <input
                                          @change="
                                            uploaderCerificateofIncoporation(
                                              $event
                                            )
                                          "
                                          class="d-none"
                                          ref="file3"
                                          type="file"
                                        />
                                        <div class="file-type-size">
                                          <p>JPEG, PNG</p>
                                          <p>Max size 5MB</p>
                                        </div>
                                      </div>
                                      <span
                                        v-if="fileSizeType && fileSizeInMb <= 5"
                                      >
                                        <img
                                          src="../assets/images/blue-good.svg"
                                          alt=""
                                        />
                                        <span>{{ fileSizeInMb }}MB</span>
                                      </span>
                                      <span
                                        v-if="fileSizeType && fileSizeInMb > 5"
                                      >
                                        <img
                                          src="../assets/images/red-wrong.svg"
                                          alt=""
                                        />
                                        <span>{{ fileSizeInMb }}MB</span>
                                      </span>
                                    </div>
                                    <div
                                      class="uplaodCertificate-holder2 d-none"
                                    >
                                      <div class="img-doc-holder">
                                        <img
                                          src="../assets/images/document-upload.svg"
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <span
                                          @click="$refs.file4.click()"
                                          class="docUpload2"
                                          >Upload Form CO2, C07, Article of
                                          Association, etc
                                          <i
                                            class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                                            v-if="loader5"
                                          ></i>
                                        </span>
                                        <input
                                          @change="uploadFormCO($event)"
                                          class="d-none"
                                          ref="file4"
                                          type="file"
                                        />
                                        <div class="file-type-size">
                                          <p>JPEG, PNG</p>
                                          <p>Max size 5MB</p>
                                        </div>
                                      </div>
                                      <span
                                        v-if="
                                          fileSizeType2 && fileSizeInMb2 <= 5
                                        "
                                      >
                                        <img
                                          src="../assets/images/blue-good.svg"
                                          alt=""
                                        />
                                        <span>{{ fileSizeInMb2 }}MB</span>
                                      </span>
                                      <span
                                        v-if="
                                          fileSizeType2 && fileSizeInMb2 > 5
                                        "
                                      >
                                        <img
                                          src="../assets/images/red-wrong.svg"
                                          alt=""
                                        />
                                        <span>{{ fileSizeInMb2 }}MB</span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="errorsO.length">
                              <ul class="error-ul">
                                <li
                                  v-for="error in errorsO"
                                  :key="error.error"
                                  class="errorMessage"
                                >
                                  {{ error }}
                                </li>
                              </ul>
                            </div>
                            <div class="form-group row">
                              <button
                                @click.prevent="postOccupation()"
                                class="btn setup-btn btn-block col-md-6 col-sm-12"
                              >
                                SAVE
                                <i
                                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                                  v-if="loaderO"
                                ></i>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Occupation ends -->

            <!-- Income begins -->
            <div
              class="tab-pane fade"
              id="financial"
              role="tabpanel"
              aria-labelledby="financial-tab"
            >
              <div class="bio-holder">
                <div class="body-holder">
                  <div class="body-wrap">
                    <div class="container body-child">
                      <div class="card">
                        <div class="card-body my-card-body">
                          <div class="text-header">
                            <h1>AVERAGE MONTHLY INCOME</h1>
                          </div>
                          <form>
                            <div class="form-group row occupation-holder">
                              <select
                                v-model="incomeSelected"
                                class="form-control col-md-6 col-sm-12 input-bar"
                                id="exampleFormControlSelect7"
                                @change="getIncomeValue($event)"
                              >
                                <option disabled value=""
                                  >| Select income range</option
                                >
                                <option
                                  v-for="(income, index) in incomeInfo"
                                  :key="index"
                                  v-bind:value="income.name"
                                  >{{ income.name }}</option
                                >
                              </select>
                            </div>
                            <div v-if="errors6.length">
                              <ul class="error-ul">
                                <li
                                  v-for="error in errors6"
                                  :key="error.error"
                                  class="errorMessage"
                                >
                                  {{ error }}
                                </li>
                              </ul>
                            </div>
                            <div class="form-group row">
                              <button
                                @click.prevent="monthlyIncome()"
                                class="btn setup-btn btn-block col-md-6 col-sm-12"
                              >
                                SAVE
                                <i
                                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                                  v-if="loaderI"
                                ></i>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Income ends -->

            <!-- Referrer begins -->
            <div
              class="tab-pane fade"
              id="referrer"
              role="tabpanel"
              aria-labelledby="referrer-tab"
            >
              <div class="bio-holder">
                <div class="body-holder">
                  <div class="body-wrap">
                    <div class="container body-child">
                      <div class="card">
                        <div class="card-body my-card-body">
                          <div class="text-header">
                            <h1>REFERER</h1>
                          </div>
                          <form>
                            <div class="form-group row">
                              <input
                                type="text"
                                class="form-control input-bar col-md-6 col-sm-12"
                                id="nameofOrganization"
                                name="nameofOrganization"
                                placeholder="|Referer code"
                                v-model="referrer"
                                :disabled="referrer"
                              />
                            </div>
                            <div class="form-group row">
                              <button
                                @click.prevent="referrerCode()"
                                class="btn setup-btn btn-block col-md-6 col-sm-12"
                              >
                                SAVE
                                <i
                                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                                  v-if="loaderR"
                                ></i>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Referrer ends -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Service } from "../store/service";
const Api = new Service();
export default {
  name: "Biodata",

  data() {
    return {
      disableInput: false,
      user: "",
      userAccount: "",
      // user: {
      //   countryid: "",
      //   stateid: "",
      //   cityid: "",
      // },
      id: "",
      el: ".formField",
      modeOfIdSelected: "",
      incomeSelected: "",
      documentType: "",
      incomeType: "",
      loader1: false,
      loaderM: false,
      loaderN: false,
      loaderO: false,
      loader: false,
      loader2: false,
      loader3: false,
      loader4: false,
      loader5: false,
      loader6: false,
      loaderI: false,
      loaderR: false,
      loaderProfile: false,
      errors: [],
      errors1: [],
      errors2: [],
      errors3: [],
      errors4: [],
      errors6: [],
      errorsN: [],
      errorsO: [],
      fileName: "",
      mobileMenu: false,
      hoverView: false,
      occupationtypes: [],
      guarantorFileName: "",
      guarantorPassportName: "",
      emplymentStatus: "",
      nameofOrganization: "",
      dateofEmployment: "",
      nameOfBusiness: "",
      jobTitle: "",
      employPhone: "",
      employAddress: "",
      occupationId: "",
      lastWorkPlace: "",
      dateOfDisengagement: "",
      unemployedAddress: "",
      currentSourceOfIncome: "",
      unemployedPhone: "",
      getCountries: "",
      // countryId: "",
      getStates: "",
      getstates: "",
      stateID: "",
      // stateId: "",
      // cityId: "",
      cityid: "",
      getcities: "",
      isRegistered: "",
      businessAddress: "",
      typeOfBusiness: "",
      businessSector: "",
      referrer: "",
      emergency: "",
      // emplymentStatus: "",
      // registrationType: "",
      // emplymentStatus: "| Employment Status",
      // businessReg: "",
      businessRegType: "",
      checkBizType: false,
      fullnamenok: "",
      registrationNumber: "",
      emailnok: "",
      phonenok: "",
      addressnok: "",
      relationshipnok: "",
      fileSizeType: false,
      fileSizeType2: false,
      coi: "",
      formCo: "",
      fileSizeInMb: "",
      fileSizeInMb2: "",
      item: "",
      identifcationType: [
        {
          name: "Voters card"
        },
        {
          name: "National passport"
        },
        {
          name: "Drivers licence"
        },
        {
          name: "International passport"
        }
      ],
      incomeInfo: [
        {
          name: "N50,000 - N100,000"
        },
        {
          name: "N100,000 - N500,000"
        },
        {
          name: "N500,000 - N1,000,000"
        },
        {
          name: "N1,000,000 - N2,000,000"
        },
        {
          name: "N2,000,000 - N5,000,000"
        },
        {
          name: "N5,000,000 - Above"
        }
      ],
      emergencyInfo: [
        {
          fullname: "",
          phone: "",
          address: "",
          relationship: ""
        }
      ],
      emergencyObj: {
        fullname: "",
        phone: "",
        address: "",
        relationship: ""
      },
      fulldata: {
        emergency_details: "",
        employeddetails: "",
        gurantor: "",
        nextofkin: "",
        selfemployed_details: ""
      },
      fullnameg: "",
      emailg: "",
      phoneg: "",
      addressg: "",
      relationshipg: "",
      passportfileg: "",

      fullnameem: "",
      phoneem: "",
      addressem: "",
      relationshipem: "",
      guarantorInfo: [],
      guarantorObj: {
        fullname: "",
        email: "",
        phone: "",
        address: "",
        relationship: "",
        formfile: "",
        passportfile: ""
      },
      who_you: "Landlord",
      occupationidLocal: "",
      filePath: "",
      organizations: "",
      openOption: false,
      org: ""
    };
  },
  beforeMount() {
    // this.getUserProfile(this.id);
  },
  mounted() {
    this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    this.userAccount = JSON.parse(
      localStorage.getItem("shelta-user")
    ).accounttype;
    this.getUserProfile(this.id);
    this.occupationidLocal = JSON.parse(
      localStorage.getItem("shelta-user")
    ).occupationid;
    this.occupationTypes();
    this.who_you = localStorage.getItem("shelta-loger");
    
    this.fetchOrganization();
    // Attach a click event listener to the document
    document.addEventListener("click", this.handleClickOutside);

  },
  beforeDestroy() {
    // Remove the click event listener when the component is about to be destroyed
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      const targetElement = this.$refs.orgg;
      if (!targetElement.contains(event.target)) {
        // The click occurred outside the target element
        // Put your code here to handle clicks outside the element
        this.openOption = false;
      }
    },
    filterOrganization(string) {
      // console.log("Org", this.nameofOrganization);
      const regexp = new RegExp(string, "i");
      const filtered = this.organizations.filter(x => regexp.test(x.firstname));
      this.organizations = filtered;
      if (this.organizations.length == 0 || this.nameofOrganization === "") {
        this.fetchOrganization();
      }
    },
    runOption(event) {
      const inputBox = this.$refs.openPanel;
      this.openOption = true;
      if (!inputBox.contains(event.target)) {
        this.openOption = false;
      }
    },
    selectPanel(name, id, item) {
      this.nameofOrganization = name;
      this.handlerId = id;
      this.employAddress = item.address;
      this.employPhone = item.phone;
      this.openOption = false;
      this.disableInput = true;
      // this.fetchPartnerServices(id);
    },
    async fetchOrganization() {
      try {
        await Api.getRequest(`fetchorganisations`)
          .then(res => {
            // console.log("Organization", res);
            this.organizations = res.data.org;
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // conole.log("closed");
      }
    },
    async fetchCountries() {
      try {
        await Api.getRequest(`fetchallcountries`)
          .then(res => {
            this.$store.commit("setApiWarning", "Loading countries...");
            // console.log(res);
            if (res.data.success) {
              this.getCountries = res.data.countries;

              this.getStatesFromCountry();
              this.$store.commit("setApiSuccess", "Countries loaded");
              this.$store.commit("setApiWarning", "");
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("closed")
      }
    },
    async getStatesFromCountry() {
      // console.log("country id", this.user.countryid);
      try {
        // await Api.getRequest(`fetchstatesbycountryid/${161}`)
        // await Api.getRequest(`fetchstatesbycountryid/${this.countryId}`)
        await Api.getRequest(`fetchstatesbycountryid/${this.user.countryid}`)
          .then(res => {
            this.$store.commit("setApiWarning", "Loading states...");
            if (res.data.success) {
              this.getstates = res.data.states;
              this.stateID = this.user.stateid;
              // this.getStateId(this.stateID);
              this.getCityFromState();
              this.$store.commit("setApiSuccess", "States loaded");
              this.$store.commit("setApiWarning", "");
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("Finanlly");
      }
    },
    getCityFromState: function() {
      Api.getRequest(`fetchcitiesbystateid/${this.stateID}`)
        .then(res => {
          this.$store.commit("setApiWarning", "Loading cities...");
          // console.log("Cities from country", res);
          if (res.data.success) {
            this.getcities = res.data.cities;
            // this.$store.commit("setApiSuccess", "States loaded");
            this.$store.commit("setApiWarning", "");
          } else if (res.data.error) {
            return this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getStateId: function(event) {
      this.stateID = event.target.value;
      this.cityid = this.event;
      this.getCityFromState();
      // this.getStatesFromCountry();
    },
    referrerCode: async function() {
      this.loaderR = true;
      if (this.referrer === "") {
        this.loaderR = false;
        return this.$store.commit("setApiFailed", "Fill realtor code");
      } else {
        try {
          await Api.postRequest(`addrealtortolandlord`, {
            landlorduserid: this.id,
            realtorcode: this.referrer
          })
            .then(res => {
              this.loaderR = false;
              if (res.data.success) {
                return this.$store.commit("setApiSuccess", res.data.success);
              }
              if (res.data.error) {
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              this.loader2 = false;
              console.log(err);
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    occupationTypes: function() {
      Api.getRequest(`fetchoccupationtypes`).then(res => {
        this.occupationtypes = res.data.occupation;
      });
    },
    getValue: function(event) {
      this.documentType = event.target.value;
    },
    addMoreEmergencyContact: function() {
      const obj = { ...this.emergencyObj };

      const arr = this.emergencyInfo;
      const rowLength = this.emergencyInfo.length;
      const formObj = this.emergencyInfo.map((item, index) => {
        if (rowLength === index + 1) {
          // console.log("Looping", item);
          if (item.fullname === "") {
            // this.errors.push("Select income range!");
            this.loader = false;
            return this.$store.commit("setApiFailed", "Fullname is needed");
          }
          if (item.phone === "") {
            // this.errors.push("Phone number is required!");
            this.loader = false;
            return this.$store.commit("setApiFailed", "Phone Number is needed");
          }
          if (item.address === "") {
            this.errors.push("Select income range!");
            this.loader = false;
            return this.$store.commit("setApiFailed", "Address is needed");
          }
          if (item.relationship === "") {
            // this.errors.push("Relationship is required!");
            this.loader = false;
            return this.$store.commit("setApiFailed", "Relationship is needed");
          } else {
            const x = arr.push(obj);
            // console.log("Push to array");
          }
        } else {
          // console.log("Dont push to array");
        }
      });

      // const obj = this.emergencyObj;
      // const arr = this.emergencyInfo;
      // const x = arr.push(obj);
    },
    removeMoreEmergencyContact: function(index) {
      this.emergencyInfo.splice(index, 1);
    },
    addEmergencyInfo: async function() {
      this.loader1 = true;
      this.errors1 = [];
      const formObj = this.emergencyInfo.map(item => {
        this.item = item;
      });
      if (this.errors1) {
        if (this.item.fullname === "") {
          this.errors1.push("Full name is required!");
          this.loader1 = false;
          // console.log("Item looped1", this.item);
        }
        if (this.item.phone === "") {
          this.errors1.push("Phone number is required!");
          // console.log("Item looped2", this.item);
          this.loader1 = false;
        }
        if (this.item.address === "") {
          this.errors1.push("Address is required!");
          // console.log("Item looped3", this.item);
          this.loader1 = false;
        }
        if (this.item.relationship === "") {
          this.errors1.push("Relationship is required!");
          // console.log("Item looped4", this.item);
          this.loader1 = false;
        }
      }
      if (this.errors1 == 0) {
        try {
          await Api.postRequest(`addemergencies`, {
            id: JSON.parse(localStorage.getItem("shelta-user")).id,
            emergencyObject: JSON.stringify(this.emergencyInfo)
          }).then(res => {
            // console.log("Emergency Object again", this.emergencyObject),
            this.loader1 = false;
            // console.log("Object", this.emergencyInfo);
            if (res.data.success) {
              this.loader1 = false;
              // console.log("success res", res);
              this.getUserProfile();
              return this.$store.commit("setApiSuccess", res.data.success);
            } else if (res.data.error) {
              this.loader1 = false;
              // console.log("Error");
              return this.$store.commit("setApiFailed", res.data.error);
            }
          });
        } finally {
          // console.log("Closed connection");
        }
      }
    },

    getFileName2: function() {
      const file = this.$refs.file9.files[0];
      this.file = this.$refs.file9.files[0];
      this.fileName = this.$refs.file9.files[0].name;
      // console.log("File name", this.file, this.fileName);
      this.filePath = URL.createObjectURL(file);
    },
    changeProfileImage: function() {
      this.loader = true;
      // this.file = this.$refs.file.files[0];
      if (!this.file) {
        this.loader = false;
        return this.$store.commit("setApiFailed", "Nothing was selected!");
      }
      if (
        this.file.type === "image/jpeg" ||
        this.file.type === "image/jpg" ||
        this.file.type === "image/png" ||
        this.file.type === "image/gif"
      ) {
        this.loader2 = true;
      } else {
        this.loader2 = false;
        return this.$store.commit(
          "setApiFailed",
          "Please select an image file"
        );
      }
      const formData = new FormData();
      const sizeInMB = (this.file.size / (1024 * 1024)).toFixed(3);
      this.fileSizeInMb = sizeInMB;
      // console.log("File Size", sizeInMB);
      // console.log("form data", formData);
      formData.append("id", this.user.id);
      formData.append("file", this.file);

      if (sizeInMB > 5) {
        this.fileSizeType = true;
        this.loader = false;
        return this.$store.commit(
          "setApiFailed",
          "File size too large, should be less than 5MB"
        );
      } else {
        Api.postRequest(`uploaduserphoto`, formData)
          .then(res => {
            // console.log("File upload", res);
            this.loader = false;
            this.fileName = "";
            if (res.data.error) {
              // console.log("error while uploading:");
              this.fileSizeType = true;
              return this.$store.commit("setApiFailed", "An error occured");
            } else {
              this.filePath = res.data.url;
              this.getUserProfile();
              this.fileSizeType = true;
              this.$store.commit("setApiSuccess", "profile picture updated");
            }
          })
          .catch(err => {
            this.loader = false;
            console.log(err);
          });
      }
    },

    socialLinkSetup: function() {
      this.loaderProfile = true;
      this.errors = [];
      if (this.user.whatsapp === "") {
        this.errors.push("WhatsApp number is required!");
        this.loader = false;
      } else {
        Api.postRequest(`updatesocialmedia`, {
          id: JSON.parse(localStorage.getItem("shelta-user")).id,
          whatsapp: this.user.whatsapp,
          website: this.user.website,
          facebook: this.user.facebook,
          instagram: this.user.instagram,
          twitter: this.user.twitter
        }).then(res => {
          if (res.data.success) {
            this.loaderProfile = false;
            // console.log("success res", res);
            localStorage.setItem("shelta-user", JSON.stringify(res.data.user));
            // return this.$store.commit("setApiSuccess", res.data.success);
            return this.$store.commit("setApiSuccess", "Success");
          } else if (res.data.error) {
            this.loaderProfile = false;
            return this.$store.commit("setApiFailed", res.data.error);
          }
        });
      }
    },

    profileSetup: function() {
      // console.log('Hellow profile', this.dob);
      this.loaderProfile = true;
      this.errors = [];
      if (this.firstname === "") {
        this.errors.push("First name is required!");
        this.loader = false;
      }
      if (this.lastname === "") {
        this.errors.push("Last name is required!");
        this.loader = false;
      }
      if (this.othernames === "") {
        this.errors.push("Other name is required!");
        this.loader = false;
      }
      if (this.email === "") {
        this.errors.push("Email is required!");
        this.loader = false;
      }
      if (this.address === "") {
        this.errors.push("Address is required!");
        this.loader = false;
      }
      if (this.user.countryid === "") {
        this.errors.push("Please select a country");
        this.loader = false;
      }
      if (this.user.stateid === "") {
        this.errors.push("Please select a state");
        this.loader = false;
      }
      if (this.user.cityid === "") {
        this.errors.push("Please select a city");
        this.loader = false;
      }
      if (this.dob === "") {
        this.errors.push("Date of birth required");
        this.loader = false;
      }
      if (this.gender === "") {
        this.errors.push("Gender is required");
        this.loader = false;
      }
      if (this.doc === "") {
        this.errors.push("Date of birth is required");
        this.loader = false;
      } else if (this.phone === "") {
        this.errors.push("Phone is required!");
        this.loader = false;
      } else {
        Api.postRequest(`updateuser`, {
          id: JSON.parse(localStorage.getItem("shelta-user")).id,
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          address: this.user.address,
          dob: this.user.dob,
          othernames: this.user.othernames,
          countryid: this.user.countryid,
          stateid: this.user.stateid,
          cityid: this.user.cityid,
          phone: this.user.phone,
          gender: this.gender
        }).then(res => {
          if (res.data.success) {
            this.loaderProfile = false;
            // console.log("success res", res);
            localStorage.setItem("shelta-user", JSON.stringify(res.data.user));
            this.socialLinkSetup();
            // return this.$store.commit("setApiSuccess", 'Success');
          } else if (res.data.error) {
            this.loaderProfile = false;
            return this.$store.commit("setApiFailed", res.data.error);
          }
        });
      }
    },

    getFileName: function() {
      this.file = this.$refs.file.files[0];
      this.fileName = this.$refs.file.files[0].name;
      const means = document.getElementById("meansID");
      means.innerHTML = "Change document";
    },
    addGuarantorPassport: async function(i, index) {
      // console.log("Click to upload guarantors passport");
      this.loader2 = true;
      this.file = this.$refs.file2.files[0];
      if (!this.file) {
        this.loader2 = false;
        return this.$store.commit("setApiFailed", "Nothing was selected!");
      }
      if (
        this.file.type === "image/jpeg" ||
        this.file.type === "image/jpg" ||
        this.file.type === "image/png" ||
        this.file.type === "image/gif"
      ) {
        this.loader2 = true;
      } else {
        this.loader2 = false;
        return this.$store.commit(
          "setApiFailed",
          "Please select an image file"
        );
      }
      const formData = new FormData();
      const sizeInMB = (this.file.size / (1024 * 1024)).toFixed(3);
      this.fileSizeInMb = sizeInMB;
      formData.append("file", this.file);
      if (sizeInMB > 5) {
        this.fileSizeType = true;
        this.loader2 = false;
        return this.$store.commit(
          "setApiFailed",
          "File size too large, should be less than 5MB"
        );
      } else {
        try {
          await Api.postRequest(`uploadgurantorpassportfile`, formData)
            .then(res => {
              this.loader2 = false;
              if (res.data.error) {
                this.fileSizeType = true;
                return this.$store.commit("setApiFailed", "An error occured");
              } else {
                this.filePath = res.data.url;
                this.getUserProfile(this.id);
                this.fileSizeType = true;
                // this.guarantorPassportName = res.data.filename;
                // this.guarantorObj.passportfile = res.data.filename;
                this.passportfileg = res.data.filename;
                // console.log("Passport File name", this.passportfileg);
                this.$store.commit(
                  "setApiSuccess",
                  "Guarantor passport updated"
                );
              }
            })
            .catch(err => {
              this.loader2 = false;
              console.log(err);
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    addGurantorFormDoc: function() {
      this.loader3 = true;
      this.file = this.$refs.file5.files[0];

      if (!this.file) {
        this.loader3 = false;
        return this.$store.commit("setApiFailed", "Nothing was selected!");
      }
      if (
        this.file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        this.file.type === "application/pdf" ||
        this.file.type === "application/vnd.oasis.opendocument.text" ||
        this.file.type === "image/jpeg" ||
        this.file.type === "image/jpg" ||
        this.file.type === "image/png" ||
        this.file.type === "image/gif"
      ) {
        this.loader3 = true;
      } else {
        this.loader3 = false;
        return this.$store.commit(
          "setApiFailed",
          "Please select a valid file type"
        );
      }
      const formData = new FormData();
      const sizeInMB = (this.file.size / (1024 * 1024)).toFixed(3);
      this.fileSizeInMb = sizeInMB;
      formData.append("file", this.file);
      if (sizeInMB > 5) {
        this.fileSizeType = true;
        this.loader3 = false;
        return this.$store.commit(
          "setApiFailed",
          "File size too large, should be less than 5MB"
        );
      } else {
        Api.postRequest(`uploadgurantorformfile`, formData)
          .then(res => {
            // console.log("File upload", res);
            this.loader3 = false;
            if (res.data.error) {
              // console.log("error while uploading:");
              this.fileSizeType = true;
              return this.$store.commit("setApiFailed", "An error occured");
            } else {
              this.filePath = res.data.url;
              this.getUserProfile(this.id);
              this.fileSizeType = true;
              // this.guarantorFileName = res.data.filename;
              this.guarantorObj.formfile = res.data.filename;

              this.$store.commit("setApiSuccess", "Guarantor's form added");
              // console.log("Uploaded Sucessfully");
              // console.log("Success file size", this.fileSizeInMb);
            }
          })
          .catch(err => {
            this.loader3 = false;
            console.log(err);
          });
      }
    },

    // addMoreGuarantorContact: function() {
    //   console.log("Add form", this.guarantorInfo);
    //   const obj = {...this.guarantorObj};
    //   const arr = this.guarantorInfo;
    //   const rowLength = this.guarantorInfo.length;
    //   const formObj = this.guarantorInfo.map((item, index) => {
    //     if (rowLength === index + 1) {
    //       console.log("Looping", item);
    //     if (item.fullname === "") {
    //       // this.errors.push("Select income range!");
    //       this.loader = false;
    //       return this.$store.commit("setApiFailed", "Fullname is needed");
    //     }
    //     if (item.phone === "") {
    //       // this.errors.push("Phone number is required!");
    //       this.loader = false;
    //       return this.$store.commit("setApiFailed", "Phone Number is needed");
    //     }
    //     if (item.email === "") {
    //       // this.errors.push("Email is required!");
    //       this.loader = false;
    //       return this.$store.commit("setApiFailed", "Email is needed");
    //     }
    //     if (item.address === "") {
    //       this.errors.push("Select income range!");
    //       this.loader = false;
    //       return this.$store.commit("setApiFailed", "Address is needed");
    //     }
    //     if (item.relationship === "") {
    //       // this.errors.push("Relationship is required!");
    //       this.loader = false;
    //       return this.$store.commit("setApiFailed", "Relationship is needed");
    //     } else {
    //        const x = arr.push(obj);
    //       console.log("Push to array");
    //     }
    //     } else {

    //       console.log("Dont push to array");
    //     }

    //   });

    // },
    removeMoreGuarantorContact: function(index) {
      this.guarantorInfo.splice(index, 1);
    },
    addGurantor: async function() {
      this.loader = true;
      this.errors2 = [];
      if (this.errors2) {
        if (this.guarantorObj.fullname === "") {
          this.errors2.push("Full name is required!");
          this.loader = false;
        }
        if (this.guarantorObj.phone === "") {
          this.errors2.push("Phone number is required!");
          this.loader = false;
        }
        if (this.guarantorObj.email === "") {
          this.errors2.push("Email is required!");
          this.loader = false;
        }
        if (this.guarantorObj.address === "") {
          this.errors2.push("Address is required!");
          this.loader = false;
        }
        if (this.guarantorObj.relationship === "") {
          this.errors2.push("Relationship is required!");
          this.loader = false;
        }
        if (this.guarantorObj.formfile === "") {
          this.errors2.push("Form file is required!");
          this.loader = false;
        }
        if (this.guarantorObj.passportfile === "") {
          this.errors2.push("Passport is required!");
          this.loader = false;
        }
      }
      if (this.errors2 == 0) {
        const obj = this.guarantorObj;
        const arr = this.guarantorInfo;
        const x = arr.push(obj);
        try {
          await Api.postRequest(`addguarantors`, {
            id: JSON.parse(localStorage.getItem("shelta-user")).id,
            guarantorsObject: JSON.stringify(this.guarantorInfo)
          })
            .then(res => {
              // console.log("Gurantor Object", this.guarantorInfo),
              this.loader = false;
              // console.log("Object", this.guarantorInfo);
              if (res.data.success) {
                this.loader = false;
                // console.log("success res", res);
                this.getUserProfile();
                return this.$store.commit("setApiSuccess", res.data.success);
              } else if (res.data.error) {
                this.loader = false;
                // console.log("Error");
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              this.loader = false;
              console.log(err);
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    createGuarantor: async function() {
      this.loader = true;
      this.errors2 = [];
      if (this.fullnameg === "") {
        this.errors2.push("Full name is required!");
        this.loader = false;
      }
      if (this.phoneg === "") {
        this.errors2.push("Phone number is required!");
        this.loader = false;
      }
      if (this.emailg === "") {
        this.errors2.push("Email is required!");
        this.loader = false;
      }
      if (this.addressg === "") {
        this.errors2.push("Address is required!");
        this.loader = false;
      }
      if (this.relationshipg === "") {
        this.errors2.push("Relationship is required!");
        this.loader = false;
      }
      // if (this.guarantorObj.formfile === "") {
      //   this.errors2.push("Form file is required!");
      //   this.loader = false;
      // }
      else {
        try {
          await Api.postRequest(`creategurantor`, {
            userId: JSON.parse(localStorage.getItem("shelta-user")).id,
            fullname: this.fullnameg,
            email: this.emailg,
            phone: this.phoneg,
            address: this.addressg,
            relationship: this.relationshipg,
            passportfile: this.passportfileg
          })
            .then(res => {
              if (res.data.success) {
                this.loader = false;
                // console.log("success res", res);
                this.getUserProfile();
                return this.$store.commit("setApiSuccess", res.data.success);
              } else if (res.data.error) {
                this.loader = false;
                // console.log("Error");
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              this.loader = false;
              console.log(err);
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    editRealtoCode() {
      this.loaderR = true;
      Api.postRequest(`editrealtorcode`, { referrer: this.referrer })
        .then(res => {
          this.loaderR = false;
        })
        .catch(err => {
          this.loaderR = false;
          console.log("err", err);
        });
    },
    editGuarantor: async function() {
      this.loader = true;
      this.errors2 = [];
      if (this.fullnameg === "") {
        this.errors2.push("Full name is required!");
        this.loader = false;
      }
      if (this.phoneg === "") {
        this.errors2.push("Phone number is required!");
        this.loader = false;
      }
      if (this.emailg === "") {
        this.errors2.push("Email is required!");
        this.loader = false;
      }
      if (this.addressg === "") {
        this.errors2.push("Address is required!");
        this.loader = false;
      }
      if (this.relationshipg === "") {
        this.errors2.push("Relationship is required!");
        this.loader = false;
      }
      // if (this.guarantorObj.formfile === "") {
      //   this.errors2.push("Form file is required!");
      //   this.loader = false;
      // }
      else {
        try {
          await Api.postRequest(`editgurantor`, {
            userId: JSON.parse(localStorage.getItem("shelta-user")).id,
            fullname: this.fullnameg,
            email: this.emailg,
            phone: this.phoneg,
            address: this.addressg,
            relationship: this.relationshipg,
            passportfile: this.passportfileg
          })
            .then(res => {
              // console.log("Create", res);
              // console.log("User Id", id);
              if (res.data.success) {
                this.loader = false;
                // console.log("success res", res);
                this.getUserProfile();
                return this.$store.commit("setApiSuccess", res.data.success);
              } else if (res.data.error) {
                this.loader = false;
                // console.log("Error");
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              this.loader = false;
              console.log(err);
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },

    createEmergency: async function() {
      this.loader1 = true;
      this.errors1 = [];
      if (this.fullnameem === "") {
        this.errors1.push("Full name is required!");
        this.loader1 = false;
      }
      if (this.phonee === "") {
        this.errors1.push("Phone number is required!");
        this.loader1 = false;
      }
      if (this.addressem === "") {
        this.errors1.push("Address is required!");
        this.loader1 = false;
      }
      if (this.relationshipem === "") {
        this.errors1.push("Relationship is required!");
        this.loader1 = false;
      }
      // if (this.guarantorObj.formfile === "") {
      //   this.errors2.push("Form file is required!");
      //   this.loader = false;
      // }
      else {
        try {
          await Api.postRequest(`createemergency`, {
            userId: JSON.parse(localStorage.getItem("shelta-user")).id,
            fullname: this.fullnameem,
            phone: this.phoneem,
            address: this.addressem,
            relationship: this.relationshipem
          })
            .then(res => {
              // console.log("Create Emmagency", res);

              if (res.data.success) {
                this.loader1 = false;
                this.getUserProfile();
                return this.$store.commit("setApiSuccess", res.data.success);
              } else if (res.data.error) {
                this.loader1 = false;
                // console.log("Error");
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              this.loader1 = false;
              console.log(err);
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    editEmergency: async function() {
      this.loader1 = true;
      this.errors1 = [];
      if (this.fullnameem === "") {
        this.errors1.push("Full name is required!");
        this.loader1 = false;
      }
      if (this.phonee === "") {
        this.errors1.push("Phone number is required!");
        this.loader1 = false;
      }
      if (this.addressem === "") {
        this.errors1.push("Address is required!");
        this.loader1 = false;
      }
      if (this.relationshipem === "") {
        this.errors1.push("Relationship is required!");
        this.loader1 = false;
      }
      // if (this.guarantorObj.formfile === "") {
      //   this.errors2.push("Form file is required!");
      //   this.loader = false;
      // }
      else {
        try {
          await Api.postRequest(`editemergency`, {
            userId: JSON.parse(localStorage.getItem("shelta-user")).id,
            fullname: this.fullnameem,
            phone: this.phoneem,
            address: this.addressem,
            relationship: this.relationshipem
          })
            .then(res => {
              if (res.data.success) {
                this.loader1 = false;
                // console.log("success res", res);
                this.getUserProfile();
                return this.$store.commit("setApiSuccess", res.data.success);
              } else if (res.data.error) {
                this.loader1 = false;
                // console.log("Error");
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              this.loader1 = false;
              console.log(err);
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },

    emagencyCheck: function() {
      if (this.fulldata.emergency_details != null) {
        this.editEmergency();
      } else {
        this.createEmergency();
      }
    },

    guarantorCheck: function() {
      if (this.fulldata.gurantor != null) {
        this.editGuarantor();
      } else {
        this.createGuarantor();
      }
    },

    // addGurantor2: async function() {
    //   this.loader = true;
    //   this.errors = [];
    //   const formObj = this.guarantorInfo.map((item) => {
    //   if (item.fullname === "") {
    //     this.errors.push("Full name is required!");
    //     this.loader = false;
    //   }
    //   if (item.phone === "") {
    //     this.errors.push("Phone number is required!");
    //     this.loader = false;
    //   }
    //   if (item.email === "") {
    //     this.errors.push("Email is required!");
    //     this.loader = false;
    //   }
    //   if (item.address === "") {
    //     this.errors.push("Address is required!");
    //     this.loader = false;
    //   }
    //   if (item.relationship === "") {
    //     this.errors.push("Relationship is required!");
    //     this.loader = false;
    //   }
    //   });

    //     try {await Api.postRequest(`addguarantors`, {
    //       id: JSON.parse(localStorage.getItem("shelta-user")).id,
    //       guarantorsObject: JSON.stringify(this.guarantorInfo),
    //     }).then((res) => {
    //       console.log("Gurantor Object", this.guarantorInfo),
    //       this.loader = false;
    //       console.log("Object", this.guarantorInfo);
    //       if (res.data.success) {
    //         this.loader = false;
    //         console.log("success res", res);
    //         this.getUserProfile();
    //         return this.$store.commit("setApiSuccess", res.data.success);
    //       } else if (res.data.error) {
    //         this.loader = false;
    //         console.log("Error");
    //         return this.$store.commit("setApiFailed", res.data.error);
    //       }

    //     }).catch((err) => {
    //         this.loader = false;
    //         console.log(err);
    //       });
    //     } finally {
    //       console.log("Closed connection");
    //     }
    // },
    addMeansofIdentification: function() {
      this.loaderM = true;
      this.file = this.$refs.file.files[0];
      if (!this.documentType) {
        this.loaderM = false;
        return this.$store.commit(
          "setApiFailed",
          "Select means of identification!"
        );
      }
      if (!this.file) {
        this.loaderM = false;
        return this.$store.commit("setApiFailed", "No file was selected!");
      }
      if (
        this.file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        this.file.type === "application/pdf" ||
        this.file.type === "application/vnd.oasis.opendocument.text" ||
        this.file.type === "image/jpeg" ||
        this.file.type === "image/jpg" ||
        this.file.type === "image/png" ||
        this.file.type === "image/gif"
      ) {
        this.loaderM = true;
      } else {
        this.loaderM = false;
        return this.$store.commit(
          "setApiFailed",
          "Please select a valid type file"
        );
      }
      const formData = new FormData();
      const sizeInMB = (this.file.size / (1024 * 1024)).toFixed(3);
      this.fileSizeInMb = sizeInMB;
      // console.log("File Size", sizeInMB);
      // console.log("form data", formData);
      formData.append("id", this.user.id);
      formData.append("file", this.file);
      formData.append("documentType", this.documentType);
      if (sizeInMB > 5) {
        this.fileSizeType = true;
        this.loader = false;
        return this.$store.commit(
          "setApiFailed",
          "File size too large, should be less than 5MB"
        );
      } else {
        Api.postRequest(`uploaduseridentity`, formData)
          .then(res => {
            // console.log("File upload", res);
            this.loaderM = false;
            if (res.data.error) {
              // console.log("error while uploading:");
              this.fileSizeType = true;
              return this.$store.commit("setApiFailed", "An error occured");
            } else {
              this.filePath = res.data.url;
              this.getUserProfile(this.id);
              this.fileSizeType = true;
              this.$store.commit("setApiSuccess", "success");
              // console.log("Uploaded Sucessfully");
              // console.log("Success file size", this.fileSizeInMb);
            }
          })
          .catch(err => {
            this.loaderM = false;
            console.log(err);
          });
      }
    },
    addNextofKin: function() {
      this.loaderN = true;
      this.errorsN = [];
      if (this.fullnamenok === "") {
        this.errorsN.push("Full name is required!");
        this.loaderN = false;
      }
      if (this.phonenok === "") {
        this.errorsN.push("Phone number is required!");
        this.loaderN = false;
      }
      if (this.emailnok === "") {
        this.errorsN.push("Email is required!");
        this.loaderN = false;
      }
      if (this.addressnok === "") {
        this.errorsN.push("Address is required!");
        this.loaderN = false;
      }
      if (this.relationshipnok === "") {
        this.errorsN.push("Relationship is required!");
        this.loaderN = false;
      } else {
        Api.postRequest(`updatenextofkin`, {
          id: JSON.parse(localStorage.getItem("shelta-user")).id,
          fullname: this.fullnamenok,
          email: this.emailnok,
          phone: this.phonenok,
          address: this.addressnok,
          relationship: this.relationshipnok
        }).then(res => {
          this.loaderN = false;
          // console.log(res);
          if (res.data.success) {
            this.loaderN = false;
            // console.log("success res", res);
            this.getUserProfile();
            return this.$store.commit("setApiSuccess", res.data.success);
          } else if (res.data.error) {
            this.loaderN = false;
            // console.log("Error");
            return this.$store.commit("setApiFailed", res.data.error);
          }
        });
      }
    },
    getIncomeValue: function(event) {
      this.incomeType = event.target.value;
    },
    monthlyIncome: function() {
      this.loaderI = true;
      this.errors6 = [];
      if (!this.incomeType) {
        this.errors6.push("Select income range!");
        this.loaderI = false;
      } else {
        Api.postRequest(`submitfinancialstatus`, {
          id: JSON.parse(localStorage.getItem("shelta-user")).id,
          averageincome: this.incomeType
        })
          .then(res => {
            // console.log("Income added", res);
            this.loaderI = false;
            if (res.data.error) {
              return this.$store.commit("setApiFailed", "An error occured");
            } else {
              this.getUserProfile(this.id);
              this.$store.commit("setApiSuccess", "success");
            }
          })
          .catch(err => {
            this.loader = false;
            console.log(err);
          });
      }
    },
    // getUserProfile: function() {
    //   Api.getRequest(`getuserbyid/${this.id}`)
    //     .then((res) => {
    //       console.log("User profile", res);
    //       this.user = res.data.user;
    //       localStorage.setItem("shelta-user", JSON.stringify(res.data.user));
    //       // console.log("countryid:", this.user.countryid);
    //     })
    //     .catch((err) => {
    //       this.loader = false;
    //       console.log(err);
    //     });
    // },

    getUserProfile: function() {
      Api.getRequest(`getuserbyid/${this.id}`).then(res => {
        // console.log("profile", res);
        if (res.status == 401) {
          window.localStorage.clear();
          return (window.location.href = "https://myshelta.com/login");
        }
        this.user = res.data.user;
        this.fetchCountries();
        // this.user.countryid = this.countryId;
        // this.stateId = this.user.stateid;
        // this.cityId = this.user.cityid;
        this.fulldata = res.data.fulldata;
        this.emergency = this.fulldata.emergency_details;

        if (this.fulldata.nextofkin != null) {
          this.fullnamenok = this.fulldata.nextofkin.fullname;
          this.phonenok = this.fulldata.nextofkin.phone;
          this.emailnok = this.fulldata.nextofkin.email;
          this.addressnok = this.fulldata.nextofkin.address;
          this.relationshipnok = this.fulldata.nextofkin.relationship;
        }

        if (this.fulldata.user_object.meansofidtype != null) {
          this.modeOfIdSelected = this.fulldata.user_object.meansofidtype;
        }

        if (this.fulldata.user_object.occupationid != null) {
          const myOccupation = this.occupationtypes.find(
            x => x.id == this.fulldata.user_object.occupationid
          );
          this.emplymentStatus = myOccupation?.occupation;
          if (this.emplymentStatus == null) {
            this.getUserProfile();
          }
          if (this.fulldata.realtorcode != null) {
            this.referrer = this.fulldata.realtorcode;
          }
          this.employmentStatusFunc(this.emplymentStatus);
          if (this.fulldata.employeddetails != null) {
            this.employAddress = this.fulldata.employeddetails.address;
            this.nameofOrganization = this.fulldata.employeddetails.nameoforganisation;
            this.jobTitle = this.fulldata.employeddetails.jobtitle;
            this.dateofEmployment = this.fulldata.employeddetails.dateofemployment;
            this.employPhone = this.fulldata.employeddetails.phone;
          }
          if (this.fulldata.selfemployed_details != null) {
            this.businessAddress = this.fulldata.selfemployed_details.businessaddress;
            this.nameOfBusiness = this.fulldata.selfemployed_details.nameofbusiness;
            this.typeOfBusiness = this.fulldata.selfemployed_details.typeofbusiness;
            this.businessSector = this.fulldata.selfemployed_details.businesssector;
            if (this.fulldata.selfemployed_details.isregistered == "0") {
              this.isRegistered = "0";
            } else {
              this.isRegistered = "1";
              this.businessRegType = this.fulldata.selfemployed_details.registrationtype;
              this.registrationNumber = this.fulldata.selfemployed_details.regnumber;
              const registeredBiz = document.getElementById(
                "registeredBusiness"
              );
              if (this.isRegistered === "1") {
                registeredBiz.style.display = "block";
                if (this.businessRegType === "Limited Liability Company") {
                  this.checkBizType = true;
                } else {
                  this.checkBizType = false;
                }
              } else {
                this.isRegistered === "0";
                registeredBiz.style.display = "none";
                //  this.businessRegType = "";
              }
            }
            // this.employPhone = this.fulldata.selfemployed_details.phone;
          }
          if (this.fulldata.unemployed != null) {
            this.unemployedAddress = this.fulldata.unemployed.address;
            this.lastWorkPlace = this.fulldata.unemployed.lastworkplace;
            this.currentSourceOfIncome = this.fulldata.unemployed.currentsourceofincome;
            this.unemployedPhone = this.fulldata.unemployed.phone;
            this.dateOfDisengagement = this.fulldata.unemployed.dateofdisengagement;
          }
        }

        if (this.fulldata.financial_status != null) {
          this.incomeSelected = this.fulldata.financial_status;
        }

        if (this.fulldata.gurantor != null) {
          this.fullnameg = this.fulldata.gurantor.fullname;
          this.emailg = this.fulldata.gurantor.email;
          this.phoneg = this.fulldata.gurantor.phone;
          this.addressg = this.fulldata.gurantor.address;
          this.relationshipg = this.fulldata.gurantor.relationship;
        }

        if (this.fulldata.emergency_details != null) {
          this.fullnameem = this.fulldata.emergency_details.fullname;
          this.emailem = this.fulldata.emergency_details.email;
          this.phoneem = this.fulldata.emergency_details.phone;
          this.addressem = this.fulldata.emergency_details.address;
          this.relationshipem = this.fulldata.emergency_details.relationship;
        }

        this.city = res.data.user.cityObject;
        this.state = res.data.user.stateObject;
        this.country = res.data.user.countryObject;
        // console.log('codeeees', this.city, this.state);
        localStorage.setItem("shelta-user", JSON.stringify(res.data.user));
      });
    },

    postOccupation: function() {
      if (
        this.emplymentStatus === "Employed" ||
        this.emplymentStatus === "NGO"
      ) {
        if (this.fulldata.employeddetails != null) {
          this.updateOccupationEmployed();
        } else {
          this.addOccupationEmployed();
        }
      } else if (
        this.emplymentStatus === "Unemployed" ||
        this.emplymentStatus === "Retired"
      ) {
        // console.log("Hello unemployed");
        if (this.fulldata.unemployed == null) {
          this.addOccupationUnemployed();
        } else {
          this.updateOccupationUnemployed();
        }
      } else if (
        this.emplymentStatus === "Enterpreneur" ||
        this.emplymentStatus === "Self Employed" ||
        this.emplymentStatus === "Business"
      ) {
        if (this.fulldata.selfemployed_details == null) {
          this.addOccupationEnterpreneur();
        } else {
          this.updateOccupationEnterpreneur();
        }
      } else {
        return this.$store.commit("setApiFailed", "Select occupation");
      }
    },
    updateOccupationEmployed: async function() {
      this.loaderO = true;
      this.errorsO = [];
      if (this.errorsO) {
        if (this.nameofOrganization == "") {
          this.errorsO.push("Name of Organization is required");
          this.loaderO = false;
        }
        if (this.dateofEmployment == "") {
          this.errorsO.push("Date of employment is required");
          this.loaderO = false;
        }
        if (this.jobTitle == "") {
          this.errorsO.push("Job Title is required");
          this.loaderO = false;
        }
        if (this.employAddress == "") {
          this.errorsO.push("Office address");
          this.loaderO = false;
        }
        if (this.employPhone == "") {
          this.errorsO.push("Employers's number");
          this.loaderO = false;
        }
      }
      if (this.errorsO == 0) {
        try {
          // edituseremployedetails
          await Api.postRequest(`edituseremployedetails`, {
            userid: JSON.parse(localStorage.getItem("shelta-user")).id,
            nameoforganisation: this.nameofOrganization,
            dateofemployment: this.dateofEmployment,
            jobtitle: this.jobTitle,
            phone: this.employPhone,
            address: this.employAddress,
            occupationid: this.occupationId
          })
            .then(res => {
              // console.log("Employed ccupation", res);
              this.loaderO = false;
              if (res.data.success) {
                this.getUserProfile();
                return this.$store.commit("setApiSuccess", res.data.success);
              } else if (res.data.error) {
                // console.log("Error");
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              this.loaderO = false;
              console.log(err);
            });
        } finally {
          // console.log("Connection closed");
        }
      }
    },
    addOccupationEmployed: async function() {
      this.loaderO = true;
      this.errorsO = [];
      if (this.errorsO) {
        if (this.nameofOrganization == "") {
          this.errorsO.push("Name of Organization is required");
          this.loaderO = false;
        }
        if (this.dateofEmployment == "") {
          this.errorsO.push("Date of employment is required");
          this.loaderO = false;
        }
        if (this.jobTitle == "") {
          this.errorsO.push("Job Title is required");
          this.loaderO = false;
        }
        if (this.employAddress == "") {
          this.errorsO.push("Office address");
          this.loaderO = false;
        }
        if (this.employPhone == "") {
          this.errorsO.push("Employers's number");
          this.loaderO = false;
        }
      }
      if (this.errorsO == 0) {
        try {
          // edituseremployedetails
          await Api.postRequest(`postuseremployedetails`, {
            userid: JSON.parse(localStorage.getItem("shelta-user")).id,
            nameoforganisation: this.nameofOrganization,
            dateofemployment: this.dateofEmployment,
            jobtitle: this.jobTitle,
            phone: this.employPhone,
            address: this.employAddress,
            occupationid: this.occupationId
          })
            .then(res => {
              // console.log("Employed ccupation", res);
              this.loaderO = false;
              if (res.data.success) {
                this.getUserProfile();
                return this.$store.commit("setApiSuccess", res.data.success);
              } else if (res.data.error) {
                // console.log("Error");
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              this.loaderO = false;
              console.log(err);
            });
        } finally {
          // console.log("Connection closed");
        }
      }
    },
    addOccupationUnemployed: async function() {
      this.loaderO = true;
      this.errorsO = [];
      if (this.errorsO) {
        if (this.lastWorkPlace == "") {
          this.errorsO.push("Last place you worked is required");
          this.loaderO = false;
        }
        if (this.dateOfDisengagement == "") {
          this.errorsO.push("Date of disengagement is required");
          this.loaderO = false;
        }
        if (this.currentSourceOfIncome == "") {
          this.errorsO.push("Current source of income required");
          this.loaderO = false;
        }
        if (this.unemployedPhone == "") {
          this.errorsO.push("Phone number is required");
          this.loaderO = false;
        }
        if (this.unemployedAddress == "") {
          this.errorsO.push("Address is required");
          this.loaderO = false;
        }
      }
      if (this.errorsO == 0) {
        try {
          await Api.postRequest(`postuserunemloyeddetails`, {
            userid: JSON.parse(localStorage.getItem("shelta-user")).id,
            lastworkplace: this.lastWorkPlace,
            dateofdisengagement: this.dateOfDisengagement,
            currentsourceofincome: this.currentSourceOfIncome,
            phone: this.unemployedPhone,
            address: this.unemployedAddress,
            occupationid: this.occupationId
          })
            .then(res => {
              // console.log("Unemployed occupation", res);
              this.loaderO = false;
              if (res.data.success) {
                this.getUserProfile();
                return this.$store.commit("setApiSuccess", res.data.success);
              } else if (res.data.error) {
                // console.log("Error");
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              this.loaderO = false;
              console.log(err);
            });
        } finally {
          // console.log("Connection closed");
        }
      }
    },
    UpdateOccupationUnemployed: async function() {
      // console.log("Just entered now");
      this.loaderO = true;
      this.errorsO = [];
      if (this.errorsO) {
        if (this.lastWorkPlace == "") {
          this.errorsO.push("Last place you worked is required");
          this.loaderO = false;
        }
        if (this.dateOfDisengagement == "") {
          this.errorsO.push("Date of disengagement is required");
          this.loaderO = false;
        }
        if (this.currentSourceOfIncome == "") {
          this.errorsO.push("Current source of income required");
          this.loaderO = false;
        }
        if (this.unemployedPhone == "") {
          this.errorsO.push("Phone number is required");
          this.loaderO = false;
        }
        if (this.unemployedAddress == "") {
          this.errorsO.push("Address is required");
          this.loaderO = false;
        }
      }
      if (this.errorsO == 0) {
        try {
          await Api.postRequest(`edituserunemloyeddetails`, {
            userid: JSON.parse(localStorage.getItem("shelta-user")).id,
            lastworkplace: this.lastWorkPlace,
            dateofdisengagement: this.dateOfDisengagement,
            currentsourceofincome: this.currentSourceOfIncome,
            phone: this.unemployedPhone,
            address: this.unemployedAddress,
            occupationid: this.occupationId
          })
            .then(res => {
              this.loaderO = false;
              if (res.data.success) {
                this.getUserProfile();
                return this.$store.commit("setApiSuccess", res.data.success);
              } else if (res.data.error) {
                // console.log("Error");
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              this.loaderO = false;
              console.log(err);
            });
        } finally {
          // console.log("Connection closed");
        }
      }
    },
    addOccupationEnterpreneur: async function() {
      this.loader0 = true;
      this.errorsO = [];
      if (this.errorsO) {
        if (this.nameOfBusiness == "") {
          this.errorsO.push("Name of business is required");
          this.loaderO = false;
        }
        if (this.typeOfBusiness == "") {
          this.errorsO.push("Type of business is required");
          this.loaderO = false;
        }
        if (this.businessSector == "") {
          this.errorsO.push("Business sector is required");
          this.loaderO = false;
        }
        if (this.businessAddress == "") {
          this.errorsO.push("Business address is required");
          this.loaderO = false;
        }

        if (this.isRegistered == "") {
          this.errorsO.push("Is bussiness registered is required");
          this.loaderO = false;
        }
        if (this.isregistered == "1" && (this.isregistered = !"")) {
          if (this.coi == "") {
            this.errorsO.push("certicate of incoporation is required");
            this.loaderO = false;
          }
          if (this.formCo == "") {
            this.errorsO.push("Form CO. is required");
            this.loaderO = false;
          }
        }
      }
      if (this.errorsO == 0) {
        try {
          await Api.postRequest(`postuserselfemployeddetails`, {
            userid: JSON.parse(localStorage.getItem("shelta-user")).id,
            nameofbusiness: this.nameOfBusiness,
            typeofbusiness: this.typeOfBusiness,
            businesssector: this.businessSector,
            businessaddress: this.businessAddress,
            isregistered: this.isRegistered,
            regnumber: this.registrationNumber,
            registrationtype: this.businessRegType,
            certificateofincoporation: this.coi,
            formco2: this.formCo,
            occupationid: this.occupationId
          })
            .then(res => {
              // console.log("self employed ccupation", res);
              this.loaderO = false;
              if (res.data.success) {
                this.getUserProfile();
                return this.$store.commit("setApiSuccess", res.data.success);
              } else if (res.data.error) {
                // console.log("Error");
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              this.loaderO = false;
              console.log(err);
            });
        } finally {
          // console.log("Connection closed");
        }
      }
    },
    updateOccupationEnterpreneur: async function() {
      this.loaderO = true;
      this.errorsO = [];
      if (this.errorsO) {
        if (this.nameOfBusiness == "") {
          this.errorsO.push("Name of business is required");
          this.loaderO = false;
        }
        if (this.typeOfBusiness == "") {
          this.errorsO.push("Type of business is required");
          this.loaderO = false;
        }
        if (this.businessSector == "") {
          this.errorsO.push("Business sector is required");
          this.loaderO = false;
        }
        if (this.businessAddress == "") {
          this.errorsO.push("Business address is required");
          this.loaderO = false;
        }

        if (this.isRegistered == "") {
          this.errorsO.push("Is bussiness registered is required");
          this.loaderO = false;
        }
        if (this.isregistered == "1" && (this.isregistered = !"")) {
          if (this.coi == "") {
            this.errorsO.push("certicate of incoporation is required");
            this.loaderO = false;
          }
          if (this.formCo == "") {
            this.errorsO.push("Form CO. is required");
            this.loaderO = false;
          }
        }
      }
      if (this.errorsO == 0) {
        try {
          await Api.postRequest(`edituserselfemployeddetails`, {
            userid: JSON.parse(localStorage.getItem("shelta-user")).id,
            nameofbusiness: this.nameOfBusiness,
            typeofbusiness: this.typeOfBusiness,
            businesssector: this.businessSector,
            businessaddress: this.businessAddress,
            isregistered: this.isRegistered,
            regnumber: this.registrationNumber,
            registrationtype: this.businessRegType,
            certificateofincoporation: this.coi,
            formco2: this.formCo,
            occupationid: this.occupationId
          })
            .then(res => {
              // console.log("self employed ccupation", res);
              this.loaderO = false;
              if (res.data.success) {
                this.getUserProfile();
                return this.$store.commit("setApiSuccess", res.data.success);
              } else if (res.data.error) {
                // console.log("Error");
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              this.loaderO = false;
              console.log(err);
            });
        } finally {
          // console.log("Connection closed");
        }
      }
    },
    employmentStatusFunc: async function(event) {
      // console.log("Employment status", event);
      // this.occupationId = event.target.key;
      // console.log("Occupation Id", this.occupationId);
      this.emplymentStatus = event;
      // console.log("Employment name", this.emplymentStatus);

      const employmentSelect = document.getElementsByClassName(
        "occupation-holder"
      );
      employmentSelect[0].style.paddingBottom = "0rem";
      const employedDiv = document.getElementById("employed");
      const employedDiv2 = document.getElementById("unemployed");
      const employedDiv3 = document.getElementById("enterpreneur");
      if (this.emplymentStatus === "employed") {
        const employedDiv = document.getElementById("Employed");
        employedDiv.style.display = "block";
        employedDiv2.style.display = "none";
        employedDiv3.style.display = "none";
      } else if (
        this.emplymentStatus === "Unemployed" ||
        this.emplymentStatus === "Retired"
      ) {
        const employedDiv2 = document.getElementById("unemployed");
        employedDiv2.style.display = "block";
        employedDiv.style.display = "none";
        employedDiv3.style.display = "none";
      } else if (
        this.emplymentStatus === "Enterpreneur" ||
        this.emplymentStatus === "Self Employed" ||
        this.emplymentStatus === "Business"
      ) {
        const employedDiv3 = document.getElementById("enterpreneur");
        employedDiv3.style.display = "block";
        employedDiv2.style.display = "none";
        employedDiv.style.display = "none";
      } else if (
        this.emplymentStatus === "Employed" ||
        this.emplymentStatus === "NGO"
      ) {
        employedDiv3.style.display = "none";
        employedDiv2.style.display = "none";
        employedDiv.style.display = "block";
      } else {
        employedDiv3.style.display = "none";
        employedDiv2.style.display = "none";
        employedDiv.style.display = "none";
        this.emplymentStatus = "";
      }
      const y = this.occupationtypes.filter(element => {
        if (element.occupation === this.emplymentStatus) {
          return (this.occupationId = element.id);
        }
      });
    },
    registeredBusiness: function(event) {
      this.isRegistered = event.target.value;
      const registeredBiz = document.getElementById("registeredBusiness");
      if (this.isRegistered === "1") {
        registeredBiz.style.display = "block";
      } else {
        this.isRegistered === "0";
        registeredBiz.style.display = "none";
        //  this.businessRegType = "";
      }
    },
    bizType: function(event) {
      this.businessRegType = event.target.value;
      // const typeOfReg = document.getElementById("llc");
      if (this.businessRegType === "Limited Liability Company") {
        // typeOfReg.style.display = "block";
        this.checkBizType = true;
      } else {
        // typeOfReg.style.display = "none";
        this.checkBizType = false;
      }
    },
    uploaderCerificateofIncoporation: function() {
      this.loader4 = true;
      this.file = this.$refs.file3.files[0];
      if (!this.file) {
        this.loader4 = false;
        return this.$store.commit("setApiFailed", "Nothing was selected!");
      }
      if (
        this.file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        this.file.type === "application/pdf" ||
        this.file.type === "application/vnd.oasis.opendocument.text"
      ) {
        this.loader4 = true;
      } else {
        this.loader4 = false;
        return this.$store.commit(
          "setApiFailed",
          "Please select a document file"
        );
      }
      const formData = new FormData();
      const sizeInMB = (this.file.size / (1024 * 1024)).toFixed(3);
      this.fileSizeInMb = sizeInMB;
      formData.append("file", this.file);
      if (sizeInMB > 0.5) {
        this.fileSizeType = true;
        this.loader4 = false;
        return this.$store.commit(
          "setApiFailed",
          "File size too large, should be less than 500KB"
        );
      } else {
        Api.postRequest(`uploadcertificateofincoporation`, formData)
          .then(res => {
            // console.log("File upload", res);
            this.loader4 = false;
            if (res.data.error) {
              this.fileSizeType = true;
              return this.$store.commit("setApiFailed", "An error occured");
            } else {
              this.filePath = res.data.url;
              this.getUserProfile(this.id);
              this.fileSizeType = true;
              // this.guarantorPassportName = res.data.filename;
              this.coi = res.data.filename;
              // console.log("COI File name", this.coi);
              this.$store.commit(
                "setApiSuccess",
                "Certificate of incoporation updated"
              );
            }
          })
          .catch(err => {
            this.loader4 = false;
            console.log(err);
          });
      }
    },
    uploadFormCO: function() {
      this.loader5 = true;
      this.file = this.$refs.file4.files[0];
      if (!this.file) {
        this.loader5 = false;
        return this.$store.commit("setApiFailed", "Nothing was selected!");
      }
      if (
        this.file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        this.file.type === "application/pdf" ||
        this.file.type === "application/vnd.oasis.opendocument.text" ||
        this.file.type === "image/jpeg" ||
        this.file.type === "image/jpg" ||
        this.file.type === "image/png" ||
        this.file.type === "image/gif"
      ) {
        this.loader5 = true;
      } else {
        this.loader5 = false;
        return this.$store.commit(
          "setApiFailed",
          "Please select a valid file type"
        );
      }
      const formData = new FormData();
      const sizeInMB = (this.file.size / (1024 * 1024)).toFixed(3);
      this.fileSizeInMb2 = sizeInMB;
      formData.append("file", this.file);
      if (sizeInMB > 5) {
        this.fileSizeType2 = true;
        this.loader5 = false;
        return this.$store.commit(
          "setApiFailed",
          "File size too large, should be less than 5MB"
        );
      } else {
        Api.postRequest(`uploadformco`, formData)
          .then(res => {
            // console.log("File upload", res);
            this.loader5 = false;
            if (res.data.error) {
              this.fileSizeType2 = true;
              return this.$store.commit("setApiFailed", "An error occured");
            } else {
              this.filePath = res.data.url;
              this.getUserProfile(this.id);
              this.fileSizeType2 = true;
              // this.guarantorPassportName = res.data.filename;
              this.formCo = res.data.filename;
              this.$store.commit("setApiSuccess", "Form C.O updated");
            }
          })
          .catch(err => {
            this.loader5 = false;
            console.log(err);
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}
// Gotham: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");

.tabs-nav {
  background-color: #ffffff;
  padding-left: 7rem;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-link {
  // color: $black;
  color: #856e6e;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 30px;
}
.nav-link.active {
  border-bottom: 1px solid $primary !important;
  // color: #856e6e;
  color: $black;
  font-weight: bold;
  border-left: none;
  border-right: none;
}

.bio_form {
  // padding-top: 3rem;
}
.user_data {
  // background: #FFFFFF;
  background: #eff0f2;
  border: 0.2px solid #cecece;
  box-sizing: border-box;
}

.bio-holder {
  margin: 2rem 3rem 4rem 3rem;
}
.text-header {
}
.text-header2 {
  padding-bottom: 1rem;
  display: flex;
  justify-content: right;
}
.text-header > h1 {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: -0.05em;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: $black;
  padding-top: 3rem;
  padding-bottom: 1rem;
}
.text-header3 > h1 {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: -0.05em;
  -webkit-text-decoration-line: underline;
  // text-decoration-line: underline;
  text-transform: uppercase;
  color: $black;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.text-header2 {
  a {
    color: inherit;
    text-decoration: none;
  }
  button {
    border: 1px solid #0033ea;
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: -0.05em;
    text-align: right;
    text-transform: uppercase;
    color: $black;
    padding: 10px;
    cursor: pointer;
    box-shadow: none;
    outline: 0;
  }
}
.body-child {
  min-height: auto;
  margin: auto;
}
.my-card-body {
  position: relative;
  box-sizing: border-box;
  padding-bottom: 4rem;
}

.emergency-top-header {
  margin: auto;
}
.emergency-top-header > div {
  // margin: auto;
  display: flex;
  justify-content: space-between;
}

.formHeader {
  color: $black;
  font-family: Gotham;
  font-size: 15px;
}

.form-group {
  justify-content: center !important;
}
.input-bar {
  background: #ffffff;
  height: 44px;
  border: 0.5px solid #445db6;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 0px;

  align-content: normal;
  font-family: Gotham;
  font-style: normal;
  // font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.05em;
  // text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.5);
}

.addMoreForm-holder {
  display: flex;
  justify-content: flex-end;
  position: relative;
  right: 24%;
  padding: 1rem 0 3rem 0;
}
.addMoreForm-holder > p {
  cursor: pointer;
}

.setup-btn:hover {
  opacity: 0.6;
}
.setup-btn:active {
  transform: translateY(4px);
}
.setup-btn {
  background: $primary;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #ffffff;
  height: 45px;
}
.uploadFile-sizeHolder {
  display: flex;
  position: relative;
}
.file_name {
  font-family: Gotham;
  font-style: normal;
  font-size: 15px;
  padding-top: 10px;
  color: #26d516;
  margin-bottom: 0;
}
.uploadFile-sizeHolder > p {
  margin-top: 1rem;
  padding-top: 0.1rem;
  border: 0.3px solid #e5e5e5;
  box-sizing: border-box;
  padding-left: 3rem;
  padding-right: 3rem;
  height: 2rem;
}
.fileSize-holder {
  position: relative;
  left: 3rem;
  bottom: 0rem;
}

.uploadDocument-holder {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.uploadDocument-holder > span:first-child {
  padding: 0px 2.5rem 0 0px;
}
.uploadDocument-holder > span:nth-child(2) {
  padding: 8px 1rem;
  border: 1px solid;
  background: $primary;
  color: $secondary;
  font-style: normal;
  font-family: Gotham;
  font-weight: 300;
  font-size: 12px;
  line-height: 25px;
  margin-right: 2rem;
}
.guarantorDocument-holder {
  padding-bottom: 2rem;
}
.uploadDocument-holder > span:nth-child(3) {
  padding: 0 1rem;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: #ea0000;
}
.uploadDocument-holder > span:nth-child(4) {
  padding: 0 1rem;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: #ea0000;
}

.guarantorDocument-holder > span:first-child {
  padding: 0px 2.5rem 0 7px;
}
.guarantorDocument-holder > span:nth-child(2) {
  padding: 8px 1rem;
  border: 1px solid;
  background: $primary;
  color: $secondary;
  font-style: normal;
  font-family: Gotham;
  font-weight: 300;
  font-size: 12px;
  line-height: 25px;
  margin-right: 0rem;
}
.guarantorDocument-holder > span:nth-child(3) {
  padding: 0 1rem;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: #ea0000;
}
.guarantorDocument-holder > span:nth-child(4) {
  padding: 0 1rem;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: #ea0000;
}

.guarantor-addMore-form {
  display: flex;
  justify-content: flex-end;
  padding-right: 13rem;
  padding-top: 3rem;
  padding-bottom: 4rem;
}
.guarantor-addMore-form > p {
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  color: $black;
}

.occupation-holder {
  padding-bottom: 3rem;
}

#employed {
  display: none;
}
#unemployed {
  display: none;
}
#enterpreneur {
  display: none;
}
#registeredBusiness {
  display: none;
}
#llc {
  display: none;
}

.uplaodCertificate-holder > div:nth-child(2) {
  border: 0.3px solid $tatiary;
  box-sizing: border-box;
  width: 280px;
  // line-height: 40px;
  height: 40px;
}
.uplaodCertificate-holder2 > div:nth-child(2) {
  border: 0.3px solid $tatiary;
  box-sizing: border-box;
  width: 280px;
  // line-height: 40px;
  height: 40px;
  background: $primary;
}
.docUpload {
  font-family: Gotham;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 40px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  color: $black;
}
.docUpload2 {
  color: $secondary;
  font-family: Gotham;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 40px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  color: $secondary;
}
.uplaodCertificate-holder {
  display: flex;
  justify-content: space-around;
}
.uplaodCertificate-holder2 {
  display: flex;
  justify-content: space-around;
}

// .img-doc-holder > img {
// }
.img-doc-holder {
  border-radius: 50px;
  width: 48px;
  height: 48px;
  border: 1px solid $tatiary;
  padding: 0.5rem;
}

.file-check-holder {
  display: flex;
  justify-content: center;
}

.file-type-size {
  display: flex;
  justify-content: space-between;
}
.file-type-size > p {
  font-family: Gotham;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: #ea0000;
}

.errorHeader {
  font-family: Gotham;
  font-weight: normal;
  font-size: 17px;
  list-style: none;
  text-align: center;
  margin-bottom: 0;
  // margin-left: 1em;
}
.errorMessage {
  font-family: Gotham;
  font-weight: normal;
  color: #e10000;
  font-size: 14px;
  list-style: none;
  text-align: center;
  padding-inline-start: 0;
}
.error-ul {
  padding-inline-start: 0;
}

// Profile pix section
.profile_pix_section {
  background: #f0f4f7;
  border: 0.2px solid #cecece;
  box-sizing: border-box;
  padding: 15px;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
    text-align: left;
    align-items: center;
    color: $black;
    padding-top: 1rem;
    padding-left: 23px;
  }
  h6:nth-child(3) {
    padding-bottom: 15px;
  }
}

.profile_img_circle {
  cursor: pointer;
  padding-top: 1.5rem;
  img {
    cursor: pointer;
    width: 133px;
    height: 133px;
    // width: 100%;
    // height: 100%;
    border: 0.5px solid #ddd5d5;
    border-radius: 50%;
    object-fit: cover;
  }
}
.blue_avatar {
  border: none !important;
}

.upload_profile {
  padding-top: 1.2rem;
  button:first-child {
    background-color: $yellow;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05em;
    border-radius: 0;
    outline: 0;
    box-shadow: none;
    margin: 0 10px;
  }
  button:nth-child(2) {
    background-color: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05em;
    border-radius: 0;
    outline: 0;
    box-shadow: none;
    margin: 0 10px;
  }
}

.profile_divider {
  background-color: $secondary;
}

.lrpadding {
  padding-right: 0;
  padding-left: 0;
}
.select-label {
  font-family: Gotham;
  font-style: normal;
  display: flex;
  padding-left: 26px;
  font-size: 13px;
}
.input-bar {
  background: #ffffff;
  height: 44px;
  border: 0.5px solid #cecece;
  // border: 0.5px solid #a2b0e3;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 0px;
}
.select-bar {
  height: 50px !important;
}
.input-group-text {
  border: 0.2px solid #cecece;
  border-radius: 0;
}
.input-group-prepend {
  margin-right: -1px;
}

.update_bio {
  padding: 0 15px 30px;

  button {
    background: $primary;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    height: 43px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    justify-content: center;
    align-items: baseline;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05em;
    // text-decoration-line: underline;
    color: $secondary;
    img {
      padding-left: 10px;
    }
  }
}
// Profile pix section

::-webkit-input-placeholder {
  /* Edge */
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 13px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
  // text-decoration-line: underline;
}

:-ms-input-placeholder {
  /* Internet Explorer */
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 13px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
  // text-decoration-line: underline;
}

::placeholder {
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 13px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
  // text-decoration-line: underline;
}

// DROPDOWN SELECT OR ADD STARTS
.panel-input {
  position: relative;
  input {
    padding-right: 30px;
    background: #ffffff;
    height: 44px;
    border: 0.5px solid #cecece;
    // border: 0.5px solid #a2b0e3;
    box-sizing: border-box;
    box-shadow: none;
    border-radius: 0px;
  }
  span {
    position: absolute;
    right: 5px;
    top: 9px;
    cursor: pointer;
    i {
      font-size: 11px;
    }
  }
}
.option-list {
  border: 0.7px solid #e5e5e5;
  border-top: 0;
  li {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.5);
    list-style-type: none;
    cursor: pointer;
    text-align: left;
    padding: 0 15px;
  }
  li:hover {
    background: $primary;
    color: $secondary;
  }
  li:nth-child(even) {
  }
  li:nth-child(odd) {
  }
}
.handler-list {
  max-height: 150px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.activeHandler {
  background: $primary !important;
  color: $secondary !important;
}
.handler-list::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
// DROPDOWN SELECT OR ADD ENDS

@media only screen and (max-width: 599px) {
  .select-label {
    padding-left: 0px;
  }
  .bio-holder {
    margin: 2rem 0 4rem 0;
  }
  .form-group > .input-bar {
    margin: 0 15px;
  }
  .panel-input {
    input {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  .lrpadding {
    margin: 0 10px;
  }
  .my-card-body {
    padding-bottom: 1rem;
  }
  .my-card-body > .row {
    // flex-direction: column-reverse;
  }
  .update_bio {
    padding: 0 50px 50px;
  }
  .user_data {
    // margin-top: 20px;
    margin-bottom: 30px;
    background: #f0f4f7;
  }
  .mo bile-flex {
    flex-wrap: nowrap !important;
  }
  .tabs-nav {
    padding-left: 0;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .text-header > h1 {
    padding-top: 0;
    padding-bottom: 0.5rem;
  }
  .text-header3 > h1 {
    padding-bottom: 0.5rem;
  }
  .upload_profile {
    display: flex;
    justify-content: center;
    button {
      font-size: 12px !important;
    }
  }
  .liks_row {
    padding-left: 0;
    padding-right: 0;
  }
  .addMoreForm-holder {
    right: 0%;
  }
  .setup-btn {
    top: 4rem;
  }
  .nav-link {
    font-size: 10px;
    line-height: 40px;
    white-space: pre;
  }
  .uploadFile-sizeHolder {
    display: block;
  }
  .uplaodCertificate-holder {
    // display: flex;
    // justify-content: space-around;
    padding: 0 !important;
  }
  .uplaodCertificate-holder2 {
    // display: flex;
    // justify-content: space-around;
    padding: 0 !important;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .uplaodCertificate-holder {
    padding: 3rem 12rem;
  }
  .uplaodCertificate-holder2 {
    padding: 3rem 12rem;
  }
  // .body-child {
  //   // max-width: 35.46rem !important;
  // }
  .telephone-wrap {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .mobile-flex {
    flex-wrap: wrap !important;
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    .panel-input {
      margin-left: -6px;
      margin-right: -6px;
    }
  }
}
</style>
